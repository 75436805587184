import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutWithForm } from '@/layouts/LayoutWithForm'
import { GrayWrapper } from '@/components/common/cases/gray-wrapper/GrayWrapper'
import { Hero } from './content/Hero'
import { Stats } from './content/Stats'
import { Progress } from './content/Progress'
import { Learning } from './content/Learning'
import { Adaptive } from './content/Adaptive'
import { LearningTwo } from './content/LearningTwo'

export function Hanni() {
  return (
    <LayoutWithForm pageName="CASE_HANNI">
      <Helmet>
        <title>Приложение для изучения фонетики китайского языка - HeksRay</title>
      </Helmet>
      <Hero />
      <GrayWrapper>
        <Stats />
        <Progress />
        <Learning />
        <Adaptive />
        <LearningTwo />
      </GrayWrapper>
    </LayoutWithForm>
  )
}
