import React from 'react'

import { CaseHero } from '@/components/common/case-hero/CaseHero'

import logo from '../images/hero/logo.svg'

export const Hero = () => {
  const props = {
    logo,
    title: 'Приложение для изучения фонетики китайского языка',
    subtitle: 'Позволяет легче учиться',
    tags: ['Design', 'iOS', 'Android', 'Management', 'Q&A'],
    area: 'Изучение фонетики китайского языка.',
    role: `Мы полностью разработали дизайн и программную часть приложения с функциями адаптивного обучения.`,
    features: [
      'Обучение в игровом формате',
      'Автоматическое определение уровня знаний и подбор заданий по сложности',
      'Возможность услышать голос диктора',
      'Возможность следить за статистикой и прогрессом',
    ],
    task: 'Разработать приложение, чтобы студенты МГУ-ППИ в Шеньчжене легко осваивали фонетику китайского языка, а их преподаватели видели прогресс.',
  }

  return <CaseHero {...props} />
}
