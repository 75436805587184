import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (min-width: 768px) {
    height: 42px;

    align-items: center;
  }
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #9b9b9b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${props => 100 / (props.valuesCount + 1)}%;
  position: relative;

  @media (min-width: 768px) {
    height: 2px;
  }
`

const ProgressLine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${props => props.progress}%;
  background: #323034;
  height: 1px;
  z-index: 2;

  @media (min-width: 768px) {
    height: 2px;
  }
`

const Dots = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 3;
`

const Dot = styled.i`
  display: block;
  width: 3px;
  height: 3px;
  background: #323034;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  @media (min-width: 768px) {
    width: 8px;
    height: 8px;
  }

  span {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #323034;
    opacity: 0;
    top: 0;
    left: 0;
    transform: translateX(-8px) translateY(-8px);

    @media (min-width: 768px) {
      width: 30px;
      height: 30px;
      transform: translateX(-11px) translateY(-11px);
    }
  }
`

const Mark = styled.p`
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #9b9b9b;
  position: absolute;
  bottom: -21px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const Label = styled.p`
  font-weight: bold;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #323034;
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  opacity: ${props => (props.isSelected ? '1' : '0')};
  transition: all 0.2s ease;
`

export const BudgetRange = ({ selectedValue, values, className, onClick }) => (
  <Container className={className}>
    <Line valuesCount={values.length}>
      <ProgressLine progress={(selectedValue / (values.length + 1)) * 100} />
      <Dots>
        {values.map(dot => (
          <Dot key={dot.value} onClick={() => onClick(dot.value)}>
            <span />
            <Mark>{dot.mark}</Mark>
            <Label isSelected={selectedValue === dot.value}>{dot.label}</Label>
          </Dot>
        ))}
      </Dots>
    </Line>
  </Container>
)
