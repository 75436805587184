import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  h3 {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #323034;
    max-width: 232px;
    margin-bottom: 8px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 18px;
      max-width: 282px;
    }
  }

  p {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #323034;
    max-width: 232px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 18px;
      max-width: 282px;
    }
  }
`

export const Title = ({ title, subtitle, className }) => (
  <Wrapper className={className}>
    <h3>{title}</h3>
    <p>{subtitle}</p>
  </Wrapper>
)
