import React from 'react'

import { CaseHero } from '@/components/common/case-hero/CaseHero'

export const Hero = () => {
  const props = {
    title: 'Проект цифрового бесмертия',
    subtitle: 'Сайт, который позволяет пользователям оставить след о себе',
    tags: ['Design', 'iOS', 'Android', 'Management', 'Q&A'],
    area: 'Технологии цифрового бесмертия и сохранения памяти о человеке навсегда.',
    role: `Мы являемся партнерами этого стартапа и взяли
      на себя весь дизайн и разработку сайта.`,
    features: [
      'Создание биографии',
      'Отправление капсул времени в будущее',
      'Создание регулярных постов о жизни',
      'Игровые механики',
      'Статистика настроения',
    ],
    task: 'Создать сайт, где пользователям было бы удобно делиться своей жизнью и опытом.',
  }

  return <CaseHero {...props} />
}
