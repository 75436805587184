import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 10px;

  transition: all 0.2s ease;
  cursor: pointer;

  border: 1px solid ${props => (props.selected ? '#323034' : '#9B9B9B')};
  color: ${props => (props.selected ? 'white' : '#323034')};
  background-color: ${props => (props.selected ? '#323034' : 'white')};

  &:hover {
    box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.4);
  }
`

export const TabSelector = ({ children, selected, className, onClick }) => (
  <Container selected={selected} className={className} onClick={onClick}>
    {children}
  </Container>
)
