import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: block;
  width: 100%;
  background: #f3f3f3;
`

export const GrayWrapper = ({ children }) => <Wrapper>{children}</Wrapper>
