import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'

import mainImage from './images/main-image.svg'

const Container = styled(BlockContainer)`
  padding: 80px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    padding: 120px 84px;
  }

  @media (min-width: 1100px) {
    padding: 120px 0;
  }
`

const Title = styled.h1`
  font-size: 16px;
  line-height: 20px;
  color: #323034;
  font-weight: bold;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }
`

const Subtitle = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #323034;
  margin-bottom: 20px;

  @media (min-width: 1100px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const Image = styled.img`
  display: block;
  width: 230px;

  @media (min-width: 1100px) {
    width: 445px;
  }
`

export const Content = () => (
  <Container>
    <Title>404</Title>
    <Subtitle>Страница не найдена</Subtitle>
    <Image src={mainImage} />
  </Container>
)
