import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import flagImg from './images/flag.svg'

const chains = [
  'Собираем требования и утверждаем общее видение продукта с Вами.',
  'Разрабатываем эскизы проекта',
  'Создаем дизайн и составлеяем техническое задание на разработку.',
  'Разрабатываем продукт по этапам.',
  'Показываем результат на каждом этапе.',
  'Вы получаете готовый продукт строго в срок.',
]

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  margin-top: 80px;

  @media (min-width: 768px) {
    margin-top: 120px;
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  margin-top: 36px;

  @media (min-width: 768px) {
    margin-top: 66px;
  }

  @media (min-width: 1100px) {
    margin-top: 100px;
  }
`

export const HowWeWorking = () => (
  <Container>
    <BlockHeader title={'Как мы работаем?'} />
    <Wrapper>{generateChain(chains)}</Wrapper>
  </Container>
)

const TextWrapper = styled.p`
  width: 252px;

  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #323034;

  position: absolute;
  right: -280px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;

    width: 240px;

    right: ${({ reverse }) => (reverse ? '-280px' : '40px')};
  }
`

const Circle = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #323034;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

const CircleWrapper = styled.div`
  position: relative;
  display: flex;
`

const FirstPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Flag = styled.img.attrs({
  src: flagImg,
})`
  position: absolute;
  top: -43%;
  right: -26%;
  width: 16px;

  @media (min-width: 768px) {
    width: 23px;
  }
`

const ChainLink = ({ title, num, reverse, last }) => (
  <CircleWrapper reverse={reverse}>
    <FirstPart>
      <Circle>
        <p>{num}</p>
        {last && <Flag />}
      </Circle>
      {!last && <Stick />}
    </FirstPart>

    <TextWrapper reverse={reverse}>{title}</TextWrapper>
  </CircleWrapper>
)

function generateChainLink(title, index, arr) {
  return <ChainLink key={index} title={title} reverse={!(index % 2)} num={index + 1} last={index === arr.length - 1} />
}

const ChainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 288px;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 100%;
    align-items: center;
  }
`

const Stick = styled.div`
  height: 46px;
  width: 1px;
  background-color: #323034;

  @media (min-width: 768px) {
    height: 100px;
    width: 2px;
  }
`

function generateChain(arr) {
  return <ChainWrapper>{arr.map(generateChainLink)}</ChainWrapper>
}
