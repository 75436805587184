import React from 'react'
import styled from 'styled-components'

import { ListItem, ListItemReverse } from './common/ListItem'

import beforeImage from '../images/before/image.svg'
import challengesImage from '../images/challenges/image.svg'
import afterImage from '../images/after/image.svg'

const Container = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;

  padding-top: 24px;
  padding-bottom: 55px;

  @media (min-width: 768px) {
    padding: 0 80px;
    padding-bottom: 60px;
    padding-top: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    max-width: 1080px;
    padding-bottom: 120px;
    padding-top: 120px;
  }
`

export const List = () => (
  <Container>
    <ListItemReverse
      title="Что сначала?"
      descr="К нам пришел заказчик и попросил в корокий срок сделать ряд сервисов для его бизнеса"
      img={beforeImage}
    />
    <ListItem
      title="Какие сложности?"
      descr="У заказчика не было конкретного технического задания. Поэтому мы разбили проект на много маленьких этапов для гибкой разработки"
      img={challengesImage}
    />
    <ListItemReverse
      title="Результат"
      descr="У нас получилось сделать проект которым доволен заказчик в короткие сроки!"
      img={afterImage}
    />
  </Container>
)
