import * as React from 'react'

import { Topbar } from '@/components/common/topbar/Topbar'
import { Footer } from '@/components/common/footer/Footer'

export function MainLayout({ children }) {
  return (
    <>
      <Topbar />

      <main>{children}</main>

      <Footer />
    </>
  )
}
