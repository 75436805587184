import * as React from 'react'
import styled from 'styled-components'
import { Ray } from '../ray/Ray'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
`

const BlockTitle = styled.h2`
  margin: 0 12px 0 0;
  color: ${props => (props.white ? 'white' : '#323034')};
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 44px;
    margin: 0 24px 0 0;
  }
`
const BlockDescription = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  color: ${props => (props.white ? 'white' : '#323034')};
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`
export const BlockHeader = ({ title, description, white, className }) => (
  <Container className={className}>
    <TitleContainer>
      <BlockTitle white={white}>{title}</BlockTitle>
      <Ray white={white} />
    </TitleContainer>
    {description && <BlockDescription white={white}>{description}</BlockDescription>}
  </Container>
)
