import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Modal } from '@/components/modal/Modal'
import { MainLayout } from '@/layouts/MainLayout'
import { UniqueHero } from './content/UniqueHero'
import { WhatIsIt } from './content/WhatIsIt'
import { Advantages } from './content/Advantages'
import { Rates } from './content/Rates'
import { HowItWorks } from './content/HowItWorks'
import { Issues } from './content/Issues'
import { Questions } from './content/Questions'
import { LetsWork } from './content/LetsWork'
import { OtherServices } from '@/components/common/other-services/OtherServices'

import mainImage from './content/images/hero/main-image.svg'
import businessImage from './content/images/business.svg'
import arendaImage from './content/images/arenda.svg'

export const MVPPage = () => {
  const [isModalOpen, setIsOpenModal] = useState(false)
  const [type, setType] = useState('error')

  const openModal = typeInc => {
    setType(typeInc)
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setIsOpenModal(false)
  }

  return (
    <MainLayout>
      <Modal isVisible={isModalOpen} openModal={openModal} closeModal={closeModal} type={type} />
      <Helmet>
        <title>MVP за 30 дней по фиксированной стоимости - HeksRay</title>
      </Helmet>
      <UniqueHero
        mainImage={mainImage}
        mainText={'MVP за 30 дней по фиксированной стоимости'}
        subTitle={'Сделайте продукт, который сможете протестировать на реальных людях или показать инвесторам'}
        buttonText={'Выбрать тариф'}
      />
      <WhatIsIt />
      <Advantages />
      <Rates />
      <HowItWorks />
      <Issues />
      <Questions />
      <LetsWork openModal={openModal} />
      <OtherServices
        title={'Наши другие услуги'}
        services={[
          {
            title: 'Стартап и Бизнес',
            description: 'Поможем с разработкой сайтов и приложений для больших и сложных проектов',
            image: businessImage,
            key: 'business',
            link: '/business',
          },
          {
            title: 'Аренда команды',
            description: 'Предоставим выделенную команду под ваш проект с помесячной оплатой',
            image: arendaImage,
            key: 'arenda',
            link: '/get-team',
          },
        ]}
      />
    </MainLayout>
  )
}
