import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import imgMobile from '../images/photo-video/mobile.png'
import imgTablet from '../images/photo-video/tablet.png'
import imgDesktop from '../images/photo-video/desktop.png'

const Container = styled(BlockContainer)`
  display: flex;
  position: relative;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const StyledTitle = styled(Title)`
  width: 272px;

  margin-left: auto;
  margin-right: auto;
  padding-bottom: 295px;
  text-align: center;

  @media (min-width: 768px) {
    width: 265px;

    margin-left: 0;
    padding-bottom: 371px;
    margin-right: 0;
  }

  @media (min-width: 1100px) {
    width: 268px;

    margin-left: 120px;
    margin-right: 0;
    padding-bottom: 689px;
  }
`

const Img = styled.img`
  position: absolute;
`

const ImgM = styled(Img)`
  display: none;

  @media (max-width: 767px) {
    display: block;

    width: 252px;

    bottom: 70px;
    right: 16px;
  }
`

const ImgT = styled(Img)`
  display: none;

  @media (min-width: 768px) and (max-width: 1099px) {
    display: block;

    width: 657px;

    bottom: 260px;
    left: 110px;
  }
`

const ImgD = styled(Img)`
  display: none;

  @media (min-width: 1100px) {
    display: block;

    width: 614px;

    bottom: 500px;
    right: 70px;
  }
`

export const PhotoVideo = () => (
  <Container>
    <StyledTitle
      title="Фото видео голос"
      subtitle="Пользователи могут прикреплять свои фотографии, видео и голосовые сообщения"
    />
    <ImgM src={imgMobile} />
    <ImgT src={imgTablet} />
    <ImgD src={imgDesktop} />
  </Container>
)
