import React from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'

import arrowRight from '../images/arrow-right.svg'

const Title = styled.h3`
  margin-top: 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding-right: 14px;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-image: url('${arrowRight}');
    width: 4px;
    height: 8px;
    background-size: 4px 8px;
    right: 0;
    top: 5px;
    background-repeat: no-repeat;
    opacity: 0.6;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 32px;

    &::after {
      width: 6px;
      height: 12px;
      background-size: 6px 12px;
      right: 0;
      top: 6px;
    }
  }
`

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.2);
  border-radius: 15px;
  max-width: 288px;
  padding: 16px 24px;
  min-height: 224px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media (min-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    max-width: 280px;
    padding: 32px 30px;
    min-height: 364px;
  }

  @media (min-width: 1100px) {
    width: 280px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &:hover {
    ${Title} {
      &::after {
        opacity: 1;
      }
    }
  }
`

const ServiceImage = styled.img`
  width: 80px;
  height: 80px;

  @media (min-width: 768px) {
    width: 170px;
    height: 170px;
  }
`

const Description = styled.p`
  margin-top: 8px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;

  @media (min-width: 1100px) {
    width: 220px;

    span {
      display: block;
    }
  }
`

export const Service = ({ image, title, description, to, alt }) => (
  <Container to={to}>
    <ServiceImage src={image} alt={alt} />
    <Title>{title}</Title>
    <Description
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    />
  </Container>
)
