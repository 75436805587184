import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import imgMobileTabletDesktop from '../images/stats/mobile-tablet-desktop.png'

const Container = styled(BlockContainer)`
  display: flex;
  position: relative;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const StyledTitle = styled(Title)`
  width: 230px;

  margin-left: 58px;
  padding-bottom: 363px;

  @media (min-width: 768px) {
    width: 256px;

    margin-left: auto;
    padding-bottom: 173px;
  }

  @media (min-width: 1100px) {
    width: 295px;

    margin-left: 120px;
    padding-bottom: 546px;
  }
`

const Img = styled.img`
  position: absolute;
`

const ImgM = styled(Img)`
  display: none;

  @media (max-width: 767px) {
    display: block;

    width: 276px;

    bottom: 40px;
    left: 0;
  }
`

const ImgT = styled(Img)`
  display: none;

  @media (min-width: 768px) and (max-width: 1099px) {
    display: block;

    width: 443px;

    bottom: 70px;
    left: 70px;
  }
`

const ImgD = styled(Img)`
  display: none;

  @media (min-width: 1100px) {
    display: block;

    width: 572px;

    bottom: 200px;
    right: 0px;
  }
`

export const Stats = () => (
  <Container>
    <StyledTitle
      title="Статистика"
      subtitle="Возможность отслеживания своего состояния в зависимостии от дня недли, месяца или времени года"
    />
    <ImgM src={imgMobileTabletDesktop} />
    <ImgT src={imgMobileTabletDesktop} />
    <ImgD src={imgMobileTabletDesktop} />
  </Container>
)
