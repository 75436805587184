import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import img from '../images/dialogs/dialogs.png'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 0 16px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    padding: 0 80px;
    padding-bottom: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    padding-bottom: 60px;
  }
`

const StyledTitle = styled(Title)`
  position: absolute;
  top: -95px;
  left: 21px;

  p,
  h3 {
    width: 232px;
  }

  @media (min-width: 768px) {
    top: -106px;
    left: 91px;

    p,
    h3 {
      width: 314px;
    }
  }

  @media (min-width: 1100px) {
    top: -110px;
    left: 22px;

    p,
    h3 {
      width: 314px;
    }
  }
`

const Img = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
`

export const Dialogs = () => (
  <Container>
    <StyledTitle
      title="Диалоги"
      subtitle="На платформе можно договориться о сессии и сразу же забронировать ее, после чего присоединиться к видеоконференции"
    />
    <Img src={img} />
  </Container>
)
