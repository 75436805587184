import React from 'react'
import styled from 'styled-components'

import { HashLink } from 'react-router-hash-link'

import { BlockContainer } from '@/components/block-container/BlockContainer'

import heroImage from './images/hero-image.svg'

const Container = styled(BlockContainer)`
  display: block;
  padding: 40px 16px 404px;
  position: relative;

  background-image: url('${heroImage}');
  background-repeat: no-repeat;
  background-size: 266px 256px;
  background-position: bottom 84px center;

  @media (min-width: 768px) {
    padding: 64px 80px 434px;
    background-size: 411px 396px;
    background-position: bottom 80px right 129px;
  }

  @media (min-width: 1100px) {
    padding: 155px 0 273px;
    background-size: 425px 409px;
    background-position: bottom 131px right 0;
  }
`

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #323034;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 52px;
    width: 611px;
    margin-bottom: 16px;
  }
`

const SubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
`

const OrderButton = styled(HashLink)`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
    border: 1px solid #323034;
    border-radius: 4px;
    transition: all 0.2s ease;
    cursor: pointer;
    padding: 14px 32px;

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #323034;

    &:hover {
      box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.4);
    }

    &:active {
      color: white;
      background-color: #323034;
    }
  }
`

export const Hero = () => (
  <Container>
    <Title>Создаем приложения и сайты для вашего бизнеса </Title>
    <SubTitle>Если вам нужен сайт или приложение, мы вам поможем</SubTitle>
    <OrderButton to="#letswork">Заказать</OrderButton>
  </Container>
)
