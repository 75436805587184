import React, { useState } from 'react'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'

import { routeNames } from '@/pages/routeNames'
import { Burger } from './burger/Burger'
import { Modal } from './modal/Modal'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import logoBig from './images/logo-big.svg'
import logoSmall from './images/logo-small.svg'
import arrowDown from './images/arrow-down.svg'

const BackgroundContainer = styled.header`
  width: 100%;
  position: relative;
`

const Content = styled.div`
  width: 100%;
  background: #fbfbfb;
  height: 48px;
  position: relative;
  z-index: 100;

  @media (min-width: 768px) {
    height: 64px;
  }

  @media (min-width: 1100px) {
    height: 72px;
  }
`

const StyledContainer = styled(BlockContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 20px 0 14px;

  @media (min-width: 768px) {
    padding: 0 86px 0 77px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const LogoLink = styled(HashLink)`
  display: flex;
  align-items: center;
  display: block;
  background-image: url('${logoSmall}');
  background-repeat: no-repeat;
  background-position: center center;
  width: 33px;
  height: 32px;
  background-size: 33px 32px;

  @media (min-width: 768px) {
    background-image: url('${logoBig}');
    width: 114px;
    height: 44px;
    background-size: 114px 44px;
  }

  @media (min-width: 1100px) {
    width: 135px;
    height: 52px;
    background-size: 135px 52px;
  }
`

const OrderButton = styled(HashLink)`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
    margin-left: auto;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 18px;
    color: white !important;
    background-color: #323034;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.4);
    }

    &:active {
      color: white;
      background-color: #323034;
    }
  }

  @media (min-width: 1100px) {
    margin-left: 60px;
  }
`

const Menu = styled.nav`
  display: none;

  @media (min-width: 1100px) {
    margin: 0;
    padding: 0;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const MenuItem = styled(HashLink)`
  margin-left: 60px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #323034;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 53px;
  left: -16px;
  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(174, 174, 192, 0.25);
  border-radius: 4px;
  display: none;
`

const MenuItemDropdown = styled.div`
  margin-left: 60px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #323034;
  position: relative;
  cursor: pointer;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-right: 18px;

  transition: all 0.1s ease-in-out;

  &:hover {
    ${DropdownContainer} {
      display: block;
    }

    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    width: 9px;
    height: 6px;
    position: absolute;
    right: 0;
    top: 34px;
    background-image: url('${arrowDown}');
    background-size: 9px 6px;
    background-repeat: no-repeat;
    opacity: 0.8;
  }
`

const DropdownItem = styled(HashLink)`
  height: 40px;
  width: 158px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323034;
  padding: 0 16px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background: #323034;
    color: #ffffff;
  }
`

const StyledBurger = styled(Burger)`
  @media (min-width: 768px) {
    margin-left: 38px;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`

export const Topbar = () => {
  const [isModalOpen, setIsOpenModal] = useState(false)

  const toggleModal = () => {
    setIsOpenModal(!isModalOpen)
  }

  return (
    <BackgroundContainer>
      <Content>
        <StyledContainer>
          <LogoLink to={{ pathname: routeNames.landing() }} />
          <Menu>
            <MenuItem to="/#portfolio">Портфолио</MenuItem>
            <MenuItemDropdown>
              Услуги
              <DropdownContainer>
                <DropdownItem to="/business">Стартап и бизнес</DropdownItem>
                <DropdownItem to="/get-team">Аренда команды</DropdownItem>
                <DropdownItem to="/mvp">MVP</DropdownItem>
              </DropdownContainer>
            </MenuItemDropdown>
            <MenuItem to="/#why-we">Почему мы</MenuItem>
            <MenuItem to="/#team">Команда</MenuItem>
          </Menu>
          <OrderButton to="#letswork">Заказать</OrderButton>
          <StyledBurger onClick={() => toggleModal()} isModalOpen={isModalOpen} />
        </StyledContainer>
      </Content>
      <Modal isModalOpen={isModalOpen} toggleModal={toggleModal} />
    </BackgroundContainer>
  )
}
