import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutWithForm } from '@/layouts/LayoutWithForm'
import { GrayWrapper } from '@/components/common/cases/gray-wrapper/GrayWrapper'
import { Hero } from './content/Hero'
import { Profile } from './content/Profile'
import { DarkTheme } from './content/DarkTheme'
import { Tournaments } from './content/Tournaments'
import { Stats } from './content/Stats'

export function CubingTime() {
  return (
    <LayoutWithForm pageName="CASE_CUBING">
      <Helmet>
        <title>Приложение для кубинга - HeksRay</title>
      </Helmet>
      <Hero />
      <GrayWrapper>
        <Profile />
        <DarkTheme />
        <Tournaments />
        <Stats />
      </GrayWrapper>
    </LayoutWithForm>
  )
}
