import React, { useState } from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Button } from '@/components/button/Button'
import { TabSelector } from '@/components/tab-selector/TabSelector'
import { Input, TextArea } from '@/components/input/Input'
import { BudgetRange } from './letswork/BudgetRange'

const Container = styled(BlockContainer)`
  padding: 0 16px;
  margin-top: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: 768px) {
    margin-top: 120px;
    padding: 0 84px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
`

const Subtitle = styled.p`
  margin-top: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 288px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;

  ${Input}:not(:first-child), ${TextArea}:not(:first-child) {
    margin-top: 20px;
  }

  @media (min-width: 768px) {
    width: 600px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 0;
  }
`

const Label = styled.label`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const TabList = styled.div`
  width: 100%;

  margin-top: 16px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
`

const StyledTab = styled(TabSelector)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media (min-width: 768px) {
    width: 192px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const BudgetSelector = styled(BudgetRange)`
  width: 100%;
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-top: 12px;
  }
`

const SubmitButton = styled(Button)`
  padding: 9px 12px;
  font-size: 14px;
  line-height: 18px;
  align-self: center;
  margin-top: 20px;

  cursor: ${props => (props.isActive ? 'pointer' : 'default')};
  pointer-events: ${props => (props.isActive ? 'auto' : 'none')};

  @media (min-width: 768px) {
    padding: 10px 50px;
    font-size: 22px;
    line-height: 28px;
  }
`

const ExtraText = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323034;

  > span.mobile-desktop {
    display: block;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    max-width: 420px;

    > span.mobile-desktop {
      display: inline;
    }
  }

  @media (min-width: 1440px) {
    position: absolute;
    top: 275px;
    left: -100px;
    text-align: left;
    max-width: 100%;

    > span.mobile-desktop {
      display: block;
    }
  }
`

const budgetValues = [
  {
    value: 1,
    mark: '500 000',
    label: 'до 500 000',
    price_from: null,
    price_to: '500000',
  },
  {
    value: 2,
    mark: '1 000 000',
    label: 'до 1 000 000',
    price_from: null,
    price_to: '1000000',
  },
  {
    value: 3,
    mark: '2 000 000',
    label: 'до 2 000 000',
    price_from: null,
    price_to: '2000000',
  },
  {
    value: 4,
    mark: '3 000 000',
    label: 'до 3 000 000',
    price_from: null,
    price_to: '3000000',
  },
  {
    value: 5,
    mark: '+3 000 000',
    label: 'от 3 000 000',
    price_from: '3000000',
    price_to: null,
  },
]

export const LetsWork = ({ openModal }) => {
  const [mobile, setMobile] = useState(false)
  const [web, setWeb] = useState(false)
  const [other, setOther] = useState(false)
  const [selectedBudget, setSelectedBudget] = useState(1)

  const [nameValue, setNameValue] = useState('')
  const [telValue, setTelValue] = useState('')
  const [descrValue, setDescrValue] = useState('')

  const handleChange = (e, field) => {
    switch (field) {
      case 'name':
        setNameValue(e.target.value)
        break
      case 'tel':
        setTelValue(e.target.value)
        break
      case 'descr':
        setDescrValue(e.target.value)
        break
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const servicesOptions = []

    if (mobile) servicesOptions.push('MOBILE_APP')
    if (web) servicesOptions.push('SITE')

    const bodyObject = {
      client_name: nameValue,
      site_page: 'STARTUP_AND_BUSINESS',
      mvp_option: '',
      services: servicesOptions || null,
      price_from: budgetValues[selectedBudget - 1].price_from,
      price_to: budgetValues[selectedBudget - 1].price_to,
      client_contact: telValue,
      project_description: descrValue,
    }

    const jsonBody = JSON.stringify(bodyObject)

    fetch('https://heksray.com/api/v1/orders/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    })
      .then(res => {
        setMobile(false)
        setWeb(false)
        setOther(false)

        setSelectedBudget(1)

        setNameValue('')
        setTelValue('')
        setDescrValue('')

        openModal('success')
      })
      .catch(error => {
        console.error('Error:', error)

        openModal('error')
      })
  }

  return (
    <Container id="letswork">
      <Title>Давайте поработаем вместе!</Title>
      <Subtitle>Нам интересно работать вместе с вами</Subtitle>
      <Form onSubmit={e => handleSubmit(e)}>
        <Label>Что Вам нужно?</Label>
        <TabList>
          <StyledTab onClick={() => setMobile(!mobile)} selected={mobile}>
            Мобильное приложение
          </StyledTab>
          <StyledTab onClick={() => setWeb(!web)} selected={web}>
            Сайт
          </StyledTab>
          <StyledTab onClick={() => setOther(!other)} selected={other}>
            Другие услуги
          </StyledTab>
        </TabList>
        <Label>Какой у вас бюджет?</Label>
        <BudgetSelector
          values={budgetValues}
          selectedValue={selectedBudget}
          onClick={value => setSelectedBudget(value)}
        />
        <Input
          type="text"
          name="name"
          placeholder="Как вас зовут?"
          value={nameValue}
          onChange={e => handleChange(e, 'name')}
        />
        <Input
          type="text"
          name="contacts"
          placeholder="Ваш телефон или электронная почта"
          value={telValue}
          onChange={e => handleChange(e, 'tel')}
        />
        <TextArea
          name="project-description"
          placeholder="Напишите вкратце о своем проекте"
          value={descrValue}
          onChange={e => handleChange(e, 'descr')}
        />
        <SubmitButton isActive={nameValue && telValue && descrValue}>Отправить</SubmitButton>
      </Form>
      <ExtraText>
        <span class="mobile-desktop">Запишитесь на бесплатную</span>{' '}
        <span class="mobile-desktop">консультацию и узнайте стоимость</span> и сроки разработки вашего проекта
      </ExtraText>
    </Container>
  )
}
