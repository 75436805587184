import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import img from '../images/fast-buy/fast-buy.png'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 0 16px;
  padding-top: 142px;
  margin-bottom: 142px;

  @media (min-width: 768px) {
    padding: 0 80px;
    padding-top: 176px;
    margin-bottom: 178px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    padding-top: 173px;
    margin-bottom: 141px;
  }
`

const StyledTitle = styled(Title)`
  position: absolute;
  top: 40px;
  left: 16px;

  p,
  h3 {
    width: 232px;
  }

  @media (min-width: 768px) {
    top: 60px;
    left: 80px;

    p,
    h3 {
      width: 282px;
    }
  }

  @media (min-width: 1100px) {
    left: auto;
    top: 60px;
    right: 0;
  }
`

const Img = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
`

export const FastBuy = () => (
  <Container>
    <StyledTitle
      title="Быстрая покупка"
      subtitle="Оплата сессий производится с банковской карты клиента при помощи платежного шлюза YouKassa"
    />
    <Img src={img} />
  </Container>
)
