import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import agileImg from './images/advantages/agile.svg'
import cheaperImg from './images/advantages/cheaper.svg'
import teamImg from './images/advantages/team.svg'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    margin-top: 30px;
    padding: 0 80px;
    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-top: 60px;
  }
`

const List = styled.ul`
  margin: 0 auto;
  padding: 0;
  margin-top: 29px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    margin-top: 60px;
  }

  @media (min-width: 1100px) {
    flex-direction: row;
    justify-content: space-between;
    width: 884px;
  }
`

const Item = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 268px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 1100px) {
    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }
`

const Image = styled.img`
  width: 180px;
  margin-bottom: 24px;
  display: block;
`

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #323034;
  margin-bottom: 12px;
`

const Descr = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323034;

  @media (min-width: 1100px) {
    width: 268px;
  }
`

export const Advantages = () => (
  <Container>
    <BlockHeader title={'Плюсы такой работы'} />
    <List>
      <Item>
        <Image src={agileImg} />
        <Title>Гибкость разработки</Title>
        <Descr>Вы можете менять направление разрабатываемого продукта в любой момент</Descr>
      </Item>
      <Item>
        <Image src={cheaperImg} />
        <Title>Дешевле, чем собрать свою</Title>
        <Descr>
          Вам не нужно тратить время и деньги на поиск сотрудников, вы сразу получаете специалистов с большим опытом
        </Descr>
      </Item>
      <Item>
        <Image src={teamImg} />
        <Title>Слаженная команда</Title>
        <Descr>
          Наши специалисты долгое время работают в команде друг с другом. Вам не надо налаживать рабочие процессы и
          создавать рабочую атмосферу
        </Descr>
      </Item>
    </List>
  </Container>
)
