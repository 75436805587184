import React from 'react'
import { Helmet } from 'react-helmet'

import { MainLayout } from '@/layouts/MainLayout'
import { Content } from './content/Content'

export const NotFound = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Страница не найдена - HeksRay</title>
      </Helmet>
      <Content />
    </MainLayout>
  )
}
