import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutWithForm } from '@/layouts/LayoutWithForm'
import { GrayWrapper } from '@/components/common/cases/gray-wrapper/GrayWrapper'
import { Hero } from './content/Hero'
import { FastBuy } from './content/FastBuy'
import { ChooseCoach } from './content/ChooseCoach'
import { TimeTable } from './content/TimeTable'
import { Dialogs } from './content/Dialogs'
import { Video } from './content/Video'

export function ITrain() {
  return (
    <LayoutWithForm pageName="CASE_ITRAIN">
      <Helmet>
        <title>Платформа для коучинга - HeksRay</title>
      </Helmet>
      <Hero />
      <GrayWrapper>
        <FastBuy />
        <ChooseCoach />
        <TimeTable />
        <Dialogs />
      </GrayWrapper>
      <Video />
    </LayoutWithForm>
  )
}
