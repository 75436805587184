import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import firstImg from './images/issues/1.svg'
import secondImg from './images/issues/2.svg'
import thirdImg from './images/issues/3.svg'

const Container = styled(BlockContainer)`
  /* display: none; */
  display: block;
  padding: 0 16px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    margin-top: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
`

const cards = [
  <Card
    title={'Тяжелые системы'}
    body={
      'Большинство стартапов добавляют в свой продукт огромное количество функционала, большая часть из которого не нужна пользователям. А MVP содержит только самый необходимый и нужный функционал'
    }
    img={firstImg}
    key={1}
  />,
  <Card
    title={'Большие затраты по времени и деньгам'}
    body={'Разработка продукта без MVP бывает очень затратна по времени и финансам из-за большого набора функционала'}
    img={secondImg}
    key={2}
  />,
  <Card
    title={'Долгий старт'}
    body={
      'Ваши конкуренты могут быстрее вас выйти на рынок и тестировать свои гипотезы, пользуясь преимуществом быстрой разработки MVP'
    }
    img={thirdImg}
    key={3}
  />,
]

export const Issues = () => (
  <Container>
    <BlockHeader title={'Проблемы большинства стартапов'} />
    <Wrapper>{cards}</Wrapper>
  </Container>
)

const CardWrapper = styled.div`
  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.2);
  border-radius: 16px;
  width: 288px;
  padding: 24px 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #323034;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (min-width: 1100px) {
      min-height: 40px;
    }
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #323034;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (min-width: 1100px) {
      width: 292px;
    }
  }

  @media (min-width: 768px) {
    width: 608px;
    padding: 24px 94px 32px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 1100px) {
    width: 340px;
    padding: 26px 24px 32px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const CardImg = styled.img`
  margin-bottom: 36px;
`

function Card({ img, title, body }) {
  return (
    <CardWrapper>
      <CardImg src={img} />
      <h3>{title}</h3>
      <p>{body}</p>
    </CardWrapper>
  )
}
