import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { BlockHeader } from '@/components/block-header/BlockHeader'
import { TeamMember } from './team-member/TeamMember'

import { data as teamMembers } from './data/data'

const Container = styled(BlockContainer)`
  margin-top: 80px;
  display: block;
  padding: 0 16px;

  @media (min-width: 768px) {
    margin-top: 120px;
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    margin-top: 120px;
    padding: 0px;
  }
`

const TeamMemberList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

  @media (min-width: 768px) {
    margin-top: 60px;
    padding: 0 68px;
  }

  @media (min-width: 1100px) {
    padding: 0 37px;
  }
`

export const Team = () => (
  <Container id="team">
    <BlockHeader title="Наша команда" />
    <TeamMemberList>
      {teamMembers.map(member => (
        <TeamMember
          key={member.slug}
          photo={member.photo}
          name={member.name}
          position={member.position}
          alt={member.alt}
        />
      ))}
    </TeamMemberList>
  </Container>
)
