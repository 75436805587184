import React from 'react'
import styled from 'styled-components'

const Container = styled.li`
  margin: 0;
  padding: 0;

  display: block;
  width: 100%;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    width: 46%;
    margin-bottom: 0;

    &:nth-child(2n + 1) {
      margin-right: 8%;
    }

    &:nth-child(-n + 2) {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 1100x) {
    width: 45%;
    margin-bottom: 0;

    &:nth-child(2n + 1) {
      margin-right: 10%;
    }
  }
`

const Image = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 8px;
  height: 80px;

  @media (min-width: 768px) {
    margin: 0;
    margin-bottom: 24px;
    height: 127px;
  }
`

const Title = styled.h3`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #323034;
  margin: 0 auto;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    text-align: left;
    margin-bottom: 16px;
  }
`

const Description = styled.p`
  display: block;
  margin: 0 auto;
  margin-top: 8px;
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  max-width: 300px;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin: 0;
  padding: 0;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #545454;

  padding-left: 14px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #dedede;
    border-radius: 50%;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @media (min-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const Advantage = ({ image, title, points, alt }) => (
  <Container>
    <Image src={image} alt={alt} />
    <Title>{title}</Title>
    <List>
      {points.map((point, index) => (
        <ListItem key={index}>{point}</ListItem>
      ))}
    </List>
  </Container>
)
