// TODO: Кнопки заказать

import React from 'react'
import styled from 'styled-components'

import { content } from './rates/content'

import { HashLink } from 'react-router-hash-link'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding: 0 80px;

    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 288px;
  margin-top: 60px;

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (min-width: 1100px) {
    width: 1038px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
`

const ListItem = styled.li`
  margin: 0;
  padding: 25px 31px;
  width: 100%;
  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    margin-bottom: 60px;
    padding: 50px 87px;
  }

  @media (min-width: 1100px) {
    padding: 60px 40px;
    margin-bottom: 0;
    width: 306px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const RateTitle = styled.h3`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #323034;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-size: 72px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 48px;
  }
`
const RatePrice = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #323034;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 44px;
  }
`

const RateTime = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #323034;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: 1100px) {
    line-height: 40px;
  }
`

const RateServices = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 226px;
  margin-bottom: 24px;

  @media (min-width: 1100px) {
    margin-bottom: 78px;
  }
`

const RateService = styled.li`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #323034;
  padding-left: 14px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background: #323034;
    border-radius: 50%;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const StyledButton = styled(HashLink)`
  color: white !important;
  background-color: #323034;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 12px;
  transition: all 0.2s ease;
  cursor: pointer;

  align-self: center;
  margin-top: auto;

  &:hover {
    box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.4);
  }

  &:active {
    color: white;
    background-color: #323034;
  }

  @media (min-width: 768px) {
    padding: 9px 12px;
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: 1100px) {
    padding: 9px 12px;
    font-size: 14px;
    line-height: 18px;
  }
`

export const Rates = () => (
  <Container id="rates">
    <BlockHeader title={'Тарифы'} />
    <List>
      {content.map(item => (
        <ListItem key={item.id}>
          <RateTitle>{item.title}</RateTitle>
          <RatePrice>{item.price}</RatePrice>
          <RateTime>{item.time}</RateTime>
          <RateServices>
            {item.services.map((service, index) => (
              <RateService key={index}>{service}</RateService>
            ))}
          </RateServices>
          <StyledButton to="#letswork">Заказать</StyledButton>
        </ListItem>
      ))}
    </List>
  </Container>
)
