import styled from 'styled-components'

export const Input = styled.input`
  background: #fbfbfb;
  border: none;
  border-radius: 4px;
  padding: 12px 12px 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
  border: 1px solid transparent;
  transition: all 0.2s ease;
  width: 100%;
  min-height: 40px;

  &:hover,
  &:focus {
    border: 1px solid #9b9b9b;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    color: #898989;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    padding: 12px 12px 8px;

    &::placeholder {
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      color: #898989;
    }
  }
`

export const TextArea = styled.textarea`
  background: #fbfbfb;
  border: none;
  border-radius: 4px;
  padding: 12px 12px 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid transparent;
  transition: all 0.2s ease;
  width: 100%;
  min-height: 120px;
  resize: none;
  overflow: auto;

  &:hover,
  &:focus {
    border: 1px solid #9b9b9b;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    color: #898989;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    padding: 12px 12px 8px;

    &::placeholder {
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      color: #898989;
    }
  }
`
