import React from 'react'

import { CaseHero } from '@/components/common/case-hero/CaseHero'

import logo from '../images/hero/logo.svg'

export const Hero = () => {
  const props = {
    logo,
    title: 'Платформа для коучинга',
    subtitle: 'Платформа позволяет находить коучей и заниматься с ними из любой точки мира',
    tags: ['Design', 'Frontend', 'Backend', 'Q&A'],
    area: `Коучинг – процесс, в ходе которого, человеку помогают достичь определенной цели, которую он перед собой поставил. `,
    role: `Полностью разработали платформу. В данный момент являемся IT-отделом в большой команде стартапа. Взяли на себя всю разработку и поддержку платформы.`,
    features: ['Поиск коуча', 'Чат клиентов и коучей', 'Расписание коучей', 'Бронирование сессий', 'Видеоконференции'],
    task: 'Сделать удобную платформу. Для коуча все должно быть собрано в одном месте: расписание, видеозвонки, оплата, отзывы. А для клиента - легкий способ найти коуча.',
  }

  return <CaseHero {...props} />
}
