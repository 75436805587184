import kirik from '../images/kirik.png'
import anil from '../images/anil.png'
import kulya from '../images/kulya.png'
import dimon from '../images/dimon.png'
import leha from '../images/leha.png'
import alina from '../images/alina.png'
import sanek from '../images/sanek.png'

export const data = [
  {
    photo: anil,
    slug: 'anil',
    name: 'Анил Панда',
    position: 'CEO',
    alt: 'CEO студии мобильной и веб разработки'
  },
  {
    photo: alina,
    slug: 'alina',
    name: 'Алина Рыбкина',
    position: 'Designer',
    alt: 'UI / UX-designer мобильных приложений и web-сайтов, проектирование интерфейсов',
  },
  {
    photo: sanek,
    slug: 'sanek',
    name: 'Александр Кочетов',
    position: 'PM',
    alt: 'Руководитель разработки приложений и web-сайтов',
  },
  {
    photo: dimon,
    slug: 'dimon',
    name: 'Дмитрий Чемеков',
    position: 'Head of Mobile',
    alt: 'Глава отдела разработки на Flutter'
  },
  {
    photo: leha,
    slug: 'leha',
    name: 'Алексей Михайлов',
    position: 'iOS developer',
    alt: 'Ios-разработка проектов ',
  },
  {
    photo: kirik,
    slug: 'kirik',
    name: 'Кирилл Плохой',
    position: 'QA',
    alt: 'Тестирование продуктов'
  },
  {
    photo: kulya,
    slug: 'kulya',
    name: 'Максим Кулькин',
    position: 'CFO',
    alt: 'Работа с финансами в веб-студии'
  },
]
