import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutWithForm } from '@/layouts/LayoutWithForm'
import { GrayWrapper } from '@/components/common/cases/gray-wrapper/GrayWrapper'
import { Hero } from './content/Hero'
import { List } from './content/List'

export function Letovo() {
  return (
    <LayoutWithForm pageName="CASE_LETOVO">
      <Helmet>
        <title>Онлайн-платформа для учеников Летово - HeksRay</title>
      </Helmet>
      <Hero />
      <GrayWrapper>
        <List />
      </GrayWrapper>
    </LayoutWithForm>
  )
}
