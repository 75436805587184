import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    margin: 0;
    justify-content: flex-start;
  }
`

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;

  background: #f3f3f3;
  border-radius: 4px;

  margin-right: 4px;

  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #323034;

  @media (min-width: 768px) {
    margin-right: 12px;
  }
`

export const TagList = ({ tags, className }) => (
  <Container className={className}>
    {tags.map((tag, index) => (
      <Tag key={index}>{tag}</Tag>
    ))}
  </Container>
)
