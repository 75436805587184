import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import imgMobile from '../images/question/mobile.png'
import imgTablet from '../images/question/tablet.png'
import imgDesktop from '../images/question/desktop.png'

const Container = styled(BlockContainer)`
  display: flex;
  position: relative;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const StyledTitle = styled(Title)`
  width: 235px;

  margin-left: 0;
  padding-bottom: 163px;

  @media (min-width: 768px) {
    width: 278px;

    margin-left: 106px;
    padding-bottom: 622px;
  }

  @media (min-width: 1100px) {
    width: 276px;

    margin-left: 220px;
    padding-bottom: 617px;
  }
`

const Img = styled.img`
  position: absolute;
`

const ImgM = styled(Img)`
  display: none;

  @media (max-width: 767px) {
    display: block;

    width: 333px;

    bottom: -10px;
    right: 0;
  }
`

const ImgT = styled(Img)`
  display: none;

  @media (min-width: 768px) and (max-width: 1099px) {
    display: block;

    width: 605px;

    top: -50px;
    right: 80px;
  }
`

const ImgD = styled(Img)`
  display: none;

  @media (min-width: 1100px) {
    display: block;

    width: 734px;

    bottom: 310px;
    right: 220px;
  }
`

export const Question = () => (
  <Container>
    <StyledTitle
      title="Вопрос дня"
      subtitle="Для лучшего вовлечения клиентов есть вопросы дня, которые обновляются ежедневно"
    />
    <ImgM src={imgMobile} />
    <ImgT src={imgTablet} />
    <ImgD src={imgDesktop} />
  </Container>
)
