import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import img from '../images/tournaments/mobile-desktop.png'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 0 16px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-bottom: 60px;
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    top: 80px;

    p,
    h3 {
      max-width: 230px;
    }
  }
`

const Img = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 288px;

  @media (min-width: 768px) {
    max-width: 574px;
  }

  @media (min-width: 1100px) {
    margin: 0;
    margin-left: 101px;
    max-width: 574px;
  }
`

export const Tournaments = () => (
  <Container>
    <StyledTitle title="Турниры" subtitle="Каждую неделю проводятся турниры по 18 различным дисциплинам" />
    <Img src={img} />
  </Container>
)
