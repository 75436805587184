import * as React from 'react'
import styled from 'styled-components'

const Container = styled.button`
  display: block;
  width: 16px;
  height: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;

  @media (min-width: 768px) {
    width: 24px;
    height: 25px;
  }
`

const Line1 = styled.span`
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #323034;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-out;

  transform: ${props => (props.isModalOpen ? 'translateY(7px) rotate(-135deg) scale(1.2)' : '')};

  @media (min-width: 768px) {
    transform: ${props => (props.isModalOpen ? 'translateY(11px) rotate(-135deg) scale(1.2)' : '')};
  }
`

const Line2 = styled.span`
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #323034;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-out;
  margin-top: 5px;

  opacity: ${props => (props.isModalOpen ? '0' : '1')};
  transform: ${props => (props.isModalOpen ? 'translateX(-50%)' : '')};

  @media (min-width: 768px) {
    margin-top: 9px;
  }
`

const Line3 = styled.span`
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #323034;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-out;
  margin-top: 5px;

  transform: ${props => (props.isModalOpen ? 'translateY(-7px) rotate(-225deg) scale(1.2)' : '')};

  @media (min-width: 768px) {
    margin-top: 9px;
    transform: ${props => (props.isModalOpen ? 'translateY(-11px) rotate(-225deg) scale(1.2)' : '')};
  }
`

export const Burger = ({ className, onClick, isModalOpen }) => (
  <Container isModalOpen={isModalOpen} onClick={onClick} className={className}>
    <Line1 isModalOpen={isModalOpen} />
    <Line2 isModalOpen={isModalOpen} />
    <Line3 isModalOpen={isModalOpen} />
  </Container>
)
