import React from 'react'

import { CaseHero } from '@/components/common/case-hero/CaseHero'

import logo from '../images/hero/logo.png'

export const Hero = () => {
  const props = {
    logo,
    title: 'Онлайн-платформа для учеников',
    subtitle: 'Помогает ученикам улучшать свои знания школьных предметов',
    tags: ['Frontend', 'Backend', 'Management', 'Q&A'],
    area: 'Онлайн-платформа для занятий по школьным предметам для учеников 5-11 классов',
    role: `Продолжили развивать платформу от предудыщей команды и полностью сделали административную панель`,
    features: [
      'Административная панель для создания обучающих ресурсов',
      'Учет результатов обучения учеников',
      'Решение тестов, олимпиадных заданий, прохождение уроков и курсов',
    ],
    task: 'Развитие и создание нового функционала в платформе для учеников и административном интерфейсе',
  }

  return <CaseHero {...props} />
}
