import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { Service } from './service/Service'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import mvpImage from './images/mvp.svg'
import startupAndBusinessImage from './images/startup-and-business.svg'
import TeamRent from './images/team-rent.svg'

const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 60px;
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    margin: 0 auto;
    margin-top: 60px;
    padding: 0;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 960px;
  }
`

const Container = styled(BlockContainer)`
  flex-direction: column;
  margin-top: 80px;
  padding: 0 16px;

  @media (min-width: 768px) {
    margin-top: 120px;
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

export const Services = () => (
  <Container id="services">
    <BlockHeader white title={'Услуги'} description={'форматы сотрудничества с нами'} />
    <ServiceList>
      <Service
        image={startupAndBusinessImage}
        title={'Стартап и Бизнес'}
        description={'Поможем с разработкой сайтов и приложений для больших и сложных проектов'}
        to={'/business'}
        alt="узнать стоимость разработки мобильного и web-приложения"
      />
      <Service
        image={mvpImage}
        title={'MVP'}
        description={
          'Разработаем упрощенную версию проекта для быстрой проверки гипотез <span>с минимальными затратами<span>'
        }
        to={'/mvp'}
        alt="планирование идеи проекта"
      />
      <Service
        image={TeamRent}
        title={'Аренда команды'}
        description={'Предоставим выделенную команду под ваш проект <span>с помесячной оплатой</span>'}
        to={'/get-team'}
        alt="гарантируется качество выполенения работы"
      />
    </ServiceList>
  </Container>
)
