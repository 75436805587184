import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Image = styled.img`
  display: block;
  margin-right: 12px;
  transform: translateY(1px);
`

const StyledLink = styled.a`
  text-decoration: none;
  color: white;
`

const Text = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
`

export const Contact = ({ icon, text, href, target }) => (
  <Container>
    <Image src={icon} />
    <StyledLink href={href} target={target ? target : '_self'}>
      <Text>{text}</Text>
    </StyledLink>
  </Container>
)
