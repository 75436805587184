import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { NavigationList } from './navigation-list/NavigationList'
import { Bottom } from './bottom/Bottom'
import { Contact } from './contact/Contact'
import { HashLink } from 'react-router-hash-link'

import phoneImage from './images/phone.svg'
import mailImage from './images/mail.svg'
import addressImage from './images/address.svg'
// import vkImage from './images/vk.svg'
// import telegramImage from './images/telegram.svg'
// import instagramImage from './images/instagram.svg'
// import facebookImage from './images/facebook.svg'

const BackgroundContainer = styled.footer`
  background-color: #323034;
`

const Container = styled(BlockContainer)`
  display: block;
  color: white;
  padding: 33px 16px 40px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 40px 80px 39px;
  }

  @media (min-width: 1100px) {
    padding: 60px 0px 100px;
  }
`

const StyledNavigationList = styled(NavigationList)``

const MainRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1099px) {
    ${StyledNavigationList} {
      width: 45%;
      margin-right: 0;
      margin-bottom: 32px;
    }

    ${StyledNavigationList}:nth-child(1) {
      margin-right: 10%;
    }

    ${StyledNavigationList}:not(:nth-child(-n + 2)) {
      width: 100%;
    }
  }

  @media (min-width: 1100px) {
    flex-wrap: nowrap;
    justify-content: space-between;

    ${StyledNavigationList} {
      width: 225px;
      margin: 0;
    }
  }
`

const StyledLink = styled(HashLink)`
  text-decoration: none;
  color: white;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`

// const SocialNetworkIcon = styled.a``

// const SocialsRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;

//   ${SocialNetworkIcon}:not(:first-child) {
//     margin-left: 12px;
//   }
// `

const Text = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`

export const Footer = () => (
  <BackgroundContainer>
    <Container>
      <MainRow>
        <StyledNavigationList title="Услуги">
          <StyledLink to="/mvp">
            <Text>MVP</Text>
          </StyledLink>
          <StyledLink to="/business">
            <Text>Стартап и бизнес</Text>
          </StyledLink>
          <StyledLink to="/get-team">
            <Text>Аренда команды</Text>
          </StyledLink>
        </StyledNavigationList>

        <StyledNavigationList title="О нас">
          <StyledLink to="/#team">
            <Text>Команда</Text>
          </StyledLink>
          <StyledLink to="/#why-we">
            <Text>Почему мы</Text>
          </StyledLink>
          <StyledLink to="/#portfolio">
            <Text>Портфолио</Text>
          </StyledLink>
        </StyledNavigationList>

        <StyledNavigationList title="Контакты">
          <Contact icon={phoneImage} text="+7 (901) 344-00-30" href="tel:+79013440030" />
          <Contact icon={mailImage} text="hello@heksray.com" href="mailto:hello@heksray.com" />
          <Contact
            icon={addressImage}
            text="Озёрная ул., 42, Москва"
            target="_blank"
            href="https://goo.gl/maps/5Z6f36jbDeKUjqDeA"
          />
        </StyledNavigationList>

        {/* <StyledNavigationList title="Соцсети">
          <SocialsRow>
            <SocialNetworkIcon href="https://instagram.com/" target="_blank">
              <img src={instagramImage} />
            </SocialNetworkIcon>
            <SocialNetworkIcon href="https://vk.com/" target="_blank">
              <img src={vkImage} />
            </SocialNetworkIcon>
            <SocialNetworkIcon href="https://facebook.com/" target="_blank">
              <img src={facebookImage} />
            </SocialNetworkIcon>
            <SocialNetworkIcon href="https://telegram.org/" target="_blank">
              <img src={telegramImage} />
            </SocialNetworkIcon>
          </SocialsRow>
        </StyledNavigationList> */}
      </MainRow>
      <Bottom />
    </Container>
  </BackgroundContainer>
)
