import { routeNames } from '@/pages/routeNames'

import { LandingPage } from './landing/LandingPage'
import { BusinessPage } from './business/BusinessPage'
import { GetTeamPage } from './get-team/GetTeamPage'
import { MVPPage } from './mvp/MVPPage'
import { CubingTime } from './cases/cubing/'
import { ITrain } from './cases/itrain/'
import { Hanni } from './cases/hanni/'
import { LeavTrace } from './cases/leavtrace/'
import { Letovo } from './cases/letovo/'
import { OPV } from './cases/opv/'
import { NotFound } from './404/404'

export const ROUTES = [
  {
    path: routeNames.landing(),
    exact: true,
    component: LandingPage,
    ssr: true,
  },
  {
    path: routeNames.business(),
    exact: true,
    component: BusinessPage,
    ssr: true,
  },
  {
    path: routeNames.getTeam(),
    exact: true,
    component: GetTeamPage,
    ssr: true,
  },
  {
    path: routeNames.mvp(),
    exact: true,
    component: MVPPage,
    ssr: true,
  },
  {
    path: routeNames.cubingTime(),
    exact: true,
    component: CubingTime,
    ssr: true,
  },
  {
    path: routeNames.iTrain(),
    exact: true,
    component: ITrain,
    ssr: true,
  },
  {
    path: routeNames.hanni(),
    exact: true,
    component: Hanni,
    ssr: true,
  },
  {
    path: routeNames.leavTrace(),
    exact: true,
    component: LeavTrace,
    ssr: true,
  },
  {
    path: routeNames.letovo(),
    exact: true,
    component: Letovo,
    ssr: true,
  },
  {
    path: routeNames.offPlanVisuals(),
    exact: true,
    component: OPV,
    ssr: true,
  },
  {
    path: '*',
    component: NotFound,
    ssr: true,
  },
]
