import React from 'react'
import styled from 'styled-components'

import { HashLink } from 'react-router-hash-link'

import { BlockContainer } from '@/components/block-container/BlockContainer'

import facebookImage from './images/facebook-image.svg'
import vkImage from './images/vk-image.svg'
import instagramImage from './images/instagram-image.svg'

const Container = styled(BlockContainer)`
  display: block;
  padding: 64px 16px 128px;
  position: relative;

  @media (min-width: 768px) {
    padding: 40px 80px 61px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const TextContainer = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 20px;

  @media (min-width: 1100px) {
    margin: 0;
    padding: 0;
    width: ${props => props.titleMaxWidth + 'px'};
  }
`

const MainText = styled.h1`
  width: 288px;
  font-size: 16px;
  line-height: 20px;
  color: #323034;
  font-weight: bold;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    width: 400px;
    margin-bottom: 12px;
  }

  @media (min-width: 1100px) {
    width: ${props => props.titleMaxWidth + 'px'};
  }
`

const SubTitle = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  width: 288px;
  color: #323034;

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (min-width: 1100px) {
    font-size: 16px;
    line-height: 20px;
    width: ${props => props.subTitleMaxWidth + 'px'};
  }

  & > span {
    display: inline-block;
    margin: 0 12px;
    background: black;
    width: 93px;
    height: 2px;
    position: relative;
    transform: translateY(-3px);

    @media (min-width: 768px) {
      width: 201px;
    }

    &::after {
      content: '';
      position: absolute;
      top: -5px;
      right: -1px;
      width: 12px;
      height: 12px;
      background: black;
      border-radius: 50%;
    }
  }
`

const OrderButton = styled(HashLink)`
  display: inline-block;
  color: #323034;
  background-color: white;
  border: 1px solid #323034;
  line-height: 18px;
  transition: all 0.2s ease;
  border-radius: 4px;
  padding: 9px 12px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.4);
  }

  &:active {
    color: white;
    background-color: #323034;
  }

  @media (min-width: 768px) {
    margin-top: 24px;
    font-size: 22px;
    padding: 10px 50px;
    line-height: 28px;
  }
`

const MainImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 80%;

  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 1100px) {
    width: 100%;
    max-width: ${props => 1034 - props.titleMaxWidth}px;
    max-height: 80%;
    margin-right: 48px;
  }
`

const Socials = styled.div`
  padding: 0;
  right: 16px;
  bottom: 60px;
  position: absolute;
  display: flex;
  z-index: 2;

  @media (min-width: 768px) {
    right: 80px;
    bottom: 153px;
    flex-direction: column;
  }

  @media (min-width: 1100px) {
    right: 0;
    bottom: 60px;
  }

  /* temp hide socials */
  display: none;
`

const SocialLink = styled.a`
  width: 28px;
  height: 28px;
  margin-left: 12px;

  @media (min-width: 768px) {
    margin-left: 0;
    margin-top: 12px;
  }
`

export const Hero = ({ mainImage, mainText, subTitle, buttonText, titleMaxWidth, subTitleMaxWidth, alt }) => (
  <Container>
    <TextContainer titleMaxWidth={titleMaxWidth}>
      <MainText titleMaxWidth={titleMaxWidth}>{mainText}</MainText>
      {subTitle ? (
        <SubTitle
          subTitleMaxWidth={subTitleMaxWidth}
          dangerouslySetInnerHTML={{
            __html: subTitle,
          }}
        />
      ) : (
        ''
      )}
      <OrderButton to="#letswork">{buttonText}</OrderButton>
    </TextContainer>
    <MainImage src={mainImage} titleMaxWidth={titleMaxWidth} alt={alt ? alt : mainText} />
    <Socials>
      <SocialLink href="#">
        <img src={facebookImage} />
      </SocialLink>
      <SocialLink href="#">
        <img src={instagramImage} />
      </SocialLink>
      <SocialLink href="#">
        <img src={vkImage} />
      </SocialLink>
    </Socials>
  </Container>
)
