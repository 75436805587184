import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import img from '../images/video/video.jpg'

const Container = styled(BlockContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  padding-top: 40px;

  @media (min-width: 768px) {
    padding: 0 80px;
    padding-top: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    padding-top: 60px;
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 26px;

  p,
  h3 {
    width: 232px;
    text-align: center;
  }

  @media (min-width: 768px) {
    margin-bottom: 33px;
    p,
    h3 {
      max-width: 100%;
      width: 387px;
    }
  }

  @media (min-width: 1100px) {
    margin-bottom: 40px;
  }
`

const Img = styled.img`
  display: block;
  margin: 0 auto;
  width: 272px;
  box-shadow: 6px 6px 30px rgba(209, 205, 199, 0.5);

  @media (min-width: 768px) {
    width: 608px;
  }

  @media (min-width: 1100px) {
    width: 100%;
  }
`

export const Video = () => (
  <Container>
    <StyledTitle
      title="Видеоконференции"
      subtitle="Возможность проведения персональных сессий и групповых вебинаров прямо на платформе"
    />
    <Img src={img} />
  </Container>
)
