import React from 'react'
import './AppStyles'
import { Pages } from './pages'
import { AppStyles } from './AppStyles'

const App = () => (
  <>
    <AppStyles />
    <Pages />
  </>
)

export default App
