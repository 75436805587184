import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import likeImg from './images/like.svg'
import thinkImg from './images/think.svg'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;

  @media (min-width: 768px) {
    margin-top: 30px;
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-top: 60px;
  }
`

const Title = styled.h3`
  margin: 0 auto;
  margin-top: 60px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: 1100px) {
    margin-top: 100px;
  }
`

const Descr = styled.p`
  margin: 0 auto;
  margin-top: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 16px;
    font-size: 16px;
    line-height: 20px;
  }
`

const Info = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  max-width: 320px;

  @media (min-width: 768px) {
    margin-top: 46px;
    max-width: 637px;
  }

  @media (min-width: 1100px) {
    margin-top: 60px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }

  p {
    text-align: right;
    max-width: 168px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    @media (min-width: 768px) {
      max-width: 300px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  img {
    height: 100px;

    @media (min-width: 768px) {
      height: 216px;
    }
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;

    p {
      text-align: left;
    }
  }
`

export const ForWhom = () => (
  <Container>
    <BlockHeader title={'Кому это подходит?'} />
    <Title>Если вы уже определили вашу аудиторию и ее потребности</Title>
    <Descr>Комплексная разработка подойдет для:</Descr>

    <Info>
      <Row>
        <img src={likeImg} alt="like" />
        <p>Стартапов, которые уже проверили свои гипотезы и уверены в своем продукте</p>
      </Row>

      <Row>
        <img src={thinkImg} alt="like" />
        <p>Бизнеса, которому нужен инструмент для повышения доходов</p>
      </Row>
    </Info>
  </Container>
)
