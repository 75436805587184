import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import mobileImg from '../images/dark-theme/mobile.png'
import dekstopImg from '../images/dark-theme/desktop.png'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 0 16px;
  margin-bottom: 44px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-bottom: 60px;
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    top: 0;

    p,
    h3 {
      max-width: 282px;
    }
  }
`

const MobileImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 288px;

  @media (min-width: 768px) {
    max-width: 574px;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`

const DesktopImg = styled.img`
  display: none;

  @media (min-width: 1100px) {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
`

export const DarkTheme = () => (
  <Container>
    <StyledTitle title="Темная тема" subtitle="Есть возможность переключения на темную тему" />
    <MobileImg src={mobileImg} />
    <DesktopImg src={dekstopImg} />
  </Container>
)
