import React from 'react'
import styled from 'styled-components'

import { OpenCaseButton } from './OpenCaseButton/OpenCaseButton'
import { TagList } from '@/components/tags/TagList'

import itrainyouExtraTabletImage from '../images/backgrounds/2-extra-image.jpg'

const ExtraTabletImage = styled.img`
  display: none;
  position: absolute;
  top: 276px;
  left: 0;
  z-index: 1;
  width: 130px;
`

const ProjectLogoImage = styled.img`
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 16px;
  }
`

const ProjectLogoText = styled.h3`
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  z-index: 2;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323034;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 16px;

    font-size: 22px;
    line-height: 28px;
  }
`

const Container = styled.div`
  display: block;
  position: relative;
  overflow: hidden;

  margin: 0 auto;
  max-width: 333px;

  padding: 0px 10px 16px;
  min-height: 630px;

  background-color: white;
  background-image: ${props => `url(${props.backgroundImageMobile})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;

  border-radius: 4px;

  &:nth-child(2) {
    ${ExtraTabletImage} {
      @media (min-width: 768px) and (max-width: 1099px) {
        display: block;
      }
    }
  }

  &:nth-child(4) {
    background-size: cover;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    min-height: 938px;
    padding: 40px 20px;
    background-image: ${props => `url(${props.backgroundImageTablet})`};

    max-width: 608px;
  }

  @media (min-width: 1100px) {
    padding: 40px;
    min-height: 554px;
    background-image: ${props => `url(${props.backgroundImage})`};

    width: 100%;
    max-width: 100%;
  }
`

const Description = styled.p`
  max-width: 440px;
  margin-bottom: 12px;
  position: relative;
  z-index: 2;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  @media (min-width: 1100px) {
    span {
      display: block;
    }
  }
`

const PlatformTagList = styled(TagList)`
  position: relative;
  z-index: 2;
  margin-bottom: 16px;
  justify-content: start;
  flex-wrap: wrap;

  & > div {
    margin-bottom: 8px;
  }

  @media (min-width: 768px) {
    margin: 0;
    margin-bottom: 20px;

    & > div {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1100px) {
    margin-bottom: 40px;
  }
`

const RatingBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
`

const Rate = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #323034;
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  flex-shrink: 0;

  @media (min-width: 768px) {
    margin-right: 12px;
    font-size: 24px;
    line-height: 28px;
    width: 69px;
    height: 69px;
  }
`

const RateDescr = styled.div`
  color: #323034;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  max-width: 274px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const StyledOpenCaseButton = styled(OpenCaseButton)`
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
  bottom: 16px;
  cursor: pointer;
  z-index: 2;

  @media (min-width: 768px) {
    left: 27px;
    bottom: 40px;
  }

  @media (min-width: 1100px) {
    left: 40px;
    bottom: 40px;
  }
`

export const PortfolioCase = ({
  logoImage,
  logoText,
  backgroundImage,
  backgroundImageTablet,
  backgroundImageMobile,
  description,
  platforms,
  rating,
  link,
}) => (
  <Container
    backgroundImage={backgroundImage}
    backgroundImageTablet={backgroundImageTablet}
    backgroundImageMobile={backgroundImageMobile}
  >
    {logoImage ? <ProjectLogoImage src={logoImage} /> : <ProjectLogoText>{logoText}</ProjectLogoText>}
    <Description
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    />
    <PlatformTagList tags={platforms} />
    {rating ? (
      <RatingBlock>
        <Rate>{rating.rate}</Rate>
        <RateDescr>{rating.descr}</RateDescr>
      </RatingBlock>
    ) : (
      ''
    )}
    <ExtraTabletImage src={itrainyouExtraTabletImage} />
    <StyledOpenCaseButton to={link} />
  </Container>
)
