import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Advantage } from './advantage/Advantage'

import technologiesImage from './images/technologies.svg'
import agileImage from './images/agile.svg'
import efficiencyImage from './images/efficiency.svg'
import uxResearchImage from './images/ux-research.svg'

const Container = styled(BlockContainer)`
  display: block;
  margin-top: 60px;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-top: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-top: 120px;
  }
`

const AdvantagesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: start;

  margin-top: 20px;
  padding: 0 17px;

  @media (min-width: 768px) {
    margin-top: 60px;
    padding: 0 30px;
  }

  @media (min-width: 1100px) {
    padding: 0 210px;
  }
`

export const WhyWe = () => (
  <Container id="why-we">
    <BlockHeader
      title="Почему мы?"
      description="нам важны проекты, которые делаем, и мы рассчитываем на долгосрочное сотрудничество."
    />
    <AdvantagesList>
      <Advantage
        image={technologiesImage}
        alt="достижение результатов"
        title="Технологии"
        points={[
          'Используем передовые технологии и средства разработки',
          'Разрабатываем кроссплатформенные приложения на Flutter',
          'Разбираемся в блокчейне и нейронных сетях',
        ]}
      />
      <Advantage
        image={agileImage}
        alt="Исследование в сфере IT"
        title="Гибкость"
        points={[
          'Работаем по agile',
          'Упрощаем и контролируем процесс, работая короткими циклами',
          'Получаем обратную связь и можем легко поменять курс развития проекта',
        ]}
      />
      <Advantage
        image={uxResearchImage}
        alt="выбор команды разрботки"
        title="UX исследование"
        points={['Погружаемся в предметную область и проводим детальное исследование']}
      />
      <Advantage
        image={efficiencyImage}
        alt="реализация проектов"
        title="Эффективность"
        points={['Выделенная команда на проект', 'Команда состоит из технических специалистов и менеджера']}
      />
    </AdvantagesList>
  </Container>
)
