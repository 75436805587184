import { createGlobalStyle } from 'styled-components'

export const AppStyles = createGlobalStyle`
  *,
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scroll-behavior: smooth;
    
  }

  html {
    font-family: 'Ubuntu', -apple-system, sans-serif;
    box-sizing: border-box;
  }

  body {
    min-width: 300px;
    overflow-x: hidden;
    color: #323034;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a:active, a:visited {
    color: inherit;
  }

  button {
    cursor: pointer;
  }
  
  a,
  input,
  button,
  textarea {
    font-family: 'Ubuntu', -apple-system, sans-serif;
  }

  a,
  button,
  img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  h1 {
    font-size: 16px;
    font-weight: bold;
    
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
  
  h2 {
    font-size: 16px;
    font-weight: bold;
    
    @media (min-width: 768px) {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
    
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  
  p {
    font-size: 14px;
    font-weight: normal;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
`
