import React from 'react'

import { CaseHero } from '@/components/common/case-hero/CaseHero'

export const Hero = () => {
  const props = {
    title: 'Платформа 3D туров по квартирам',
    tags: ['Backend', 'Management', 'Q&A'],
    area: 'Сервисы в секторе строительной недвижимости США',
    role: 'Полностью разработали серверную часть веб-приложения',
    features: [
      'Административная панель для добавления в базу новых квартир',
      'Просмотр туров в 3D онлайн',
      'Инструмент для добавления и просмотра мебели в квартире',
    ],
    task: 'Разработать серверную часть и настроить инфраструктуру',
  }

  return <CaseHero {...props} />
}
