import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import img from '../images/stats/mobile-desktop.png'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 0 16px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding: 0 80px;
    padding-bottom: 96px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    padding-bottom: 96px;
    display: flex;
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 0;
    position: absolute;
    left: 0;
    top: 414px;

    p,
    h3 {
      max-width: 225px;
    }
  }
`

const Img = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 288px;

  @media (min-width: 768px) {
    max-width: 574px;
  }

  @media (min-width: 1100px) {
    margin: 0;
    margin-left: auto;
    max-width: 574px;
  }
`

export const Stats = () => (
  <Container>
    <StyledTitle title="Статистика" subtitle="В приложении сохраняются личные результаты" />
    <Img src={img} />
  </Container>
)
