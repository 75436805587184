import React from 'react'
import styled from 'styled-components'

import { ListItem, ListItemReverse } from './common/ListItem'

import beforeImage from '../images/before/image.svg'
import communicationImage from '../images/communication/image.svg'
import transferImage from '../images/transfer/image.svg'
import afterImage from '../images/after/image.svg'

const Container = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;

  padding-top: 24px;
  padding-bottom: 55px;

  @media (min-width: 768px) {
    padding: 0 80px;
    padding-bottom: 60px;
    padding-top: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    max-width: 1080px;
    padding-bottom: 120px;
    padding-top: 120px;
  }
`

export const List = () => (
  <Container>
    <ListItemReverse
      title="Что было сначала?"
      descr="К нам пришел заказчик с проектом, который уже был “в сети”. Платформа была разработана другой командой и нуждалась в глубокой переработке старого функционала и добавлении нового."
      img={beforeImage}
    />
    <ListItem
      title="Коммуникация"
      descr="Вначале наш менеджер вел две команды — нашу и предыдущую, чтобы держать все под контролем."
      img={communicationImage}
    />
    <ListItemReverse
      title="Передача кода"
      descr="В проекте было много старого кода, который надо было улучшить, и наша команда с этим справилась."
      img={transferImage}
    />
    <ListItem
      title="Как сейчас"
      descr="Наша команда является основным, единственным и постоянным разработчиком. И мы регулярно дорабатываем новые функции."
      img={afterImage}
    />
  </Container>
)
