import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import mainImage from './images/what/main-image.svg'
import boldMainImage from './images/what/bold-main-image.svg'

import tabletArrows from './images/what/tablet-arrows.svg'
import desktopArrows from './images/what/desktop-arrows.png'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  position: relative;

  @media (min-width: 768px) {
    margin-top: 30px;
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-top: 60px;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  position: relative;

  @media (min-width: 768px) {
    margin: 0 auto;
    margin-top: 0;
    width: 608px;
  }

  @media (min-width: 1100px) {
    width: 100%;
  }
`

const ListItem = styled.li`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #323034;
  margin-bottom: 20px;
  padding-left: 40px;
  position: relative;

  @media (max-width: 767px) {
    &::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background: #323034;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    margin-bottom: 0;
    padding-left: 0px;

    &:nth-child(1) {
      top: 365px;
      left: 170px;
      width: 198px;
    }

    &:nth-child(2) {
      top: 350px;
      left: 390px;
      width: 176px;
    }

    &:nth-child(3) {
      top: 170px;
      left: 10px;
      width: 163px;
    }

    &:nth-child(4) {
      top: 82px;
      left: 455px;
      width: 142px;
      text-align: right;
    }

    &:nth-child(5) {
      top: 41px;
      left: 10px;
      width: 250px;
    }
  }

  @media (min-width: 1100px) {
    font-size: 16px;
    line-height: 20px;

    &:nth-child(1) {
      top: 70px;
      left: 10px;
      width: 282px;
    }

    &:nth-child(2) {
      top: 54px;
      left: 843px;
      width: 176px;
    }

    &:nth-child(3) {
      top: 322px;
      left: 10px;
      width: 215px;
    }

    &:nth-child(4) {
      top: 455px;
      left: 417px;
      width: 176px;
      text-align: left;
    }

    &:nth-child(5) {
      top: 415px;
      left: 762px;
      width: 288px;
    }
  }
`

const MainImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 242px;
  margin-top: 20px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    display: none;
  }
`

const BoldMainImage = styled.img`
  display: none;

  @media (min-width: 768px) {
    display: block;
    margin: 0 auto;
    width: 180px;
    margin-top: 156px;
    margin-bottom: 246px;
  }

  @media (min-width: 1100px) {
    width: 242px;
    margin-top: 134px;
    margin-bottom: 273px;
  }
`

const TabletArrows = styled.img`
  display: none;

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translateX(-50%) translateX(-1px);
  }

  @media (min-width: 1100px) {
    display: none;
  }
`

const DesktopArrows = styled.img`
  display: none;

  @media (min-width: 1100px) {
    display: block;
    position: absolute;
    top: 94px;
    left: 50%;
    transform: translateX(-50%) translateX(-13px);
  }
`

export const WhatIsIt = () => (
  <Container>
    <BlockHeader title={'Что это такое'} />
    <List>
      <ListItem>Возможность менять требования проекта в любой момент</ListItem>
      <ListItem>Укомплектованная, слаженная команда</ListItem>
      <ListItem>Гибкая постановка задач</ListItem>
      <ListItem>Ежемесячная оплата</ListItem>
      <ListItem>Команда набирается под нужды проекта (менеджмент, фронтенд, бэкенд, тестирования)</ListItem>
    </List>
    <MainImage src={mainImage} />
    <BoldMainImage src={boldMainImage} />
    <TabletArrows src={tabletArrows} />
    <DesktopArrows src={desktopArrows} />
  </Container>
)
