import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import imgMobile from '../images/capsule/mobile.png'
import imgTabletDesktop from '../images/capsule/tablet-desktop.png'

const Container = styled(BlockContainer)`
  display: flex;
  position: relative;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const StyledTitle = styled(Title)`
  width: 200px;

  margin-left: 0;
  padding-bottom: 503px;

  @media (min-width: 768px) {
    width: 211px;

    margin-left: 0;
    padding-bottom: 499px;
  }

  @media (min-width: 1100px) {
    width: 276px;

    margin-left: auto;
    padding-bottom: 286px;

    margin-right: 200px;
  }
`

const Img = styled.img`
  position: absolute;
`

const ImgM = styled(Img)`
  display: none;

  @media (max-width: 767px) {
    display: block;

    width: 243px;

    bottom: 50px;
    left: 16px;
  }
`

const ImgT = styled(Img)`
  display: none;

  @media (min-width: 768px) and (max-width: 1099px) {
    display: block;

    width: 397px;

    bottom: 250px;
    right: 0;
  }
`

const ImgD = styled(Img)`
  display: none;

  @media (min-width: 1100px) {
    display: block;

    width: 515px;

    top: -466px;
    right: 0;
  }
`

export const Capsule = () => (
  <Container>
    <StyledTitle title="Капсулы времени" subtitle="Есть возможность отложенного постинга в будущее" />
    <ImgM src={imgMobile} />
    <ImgT src={imgTabletDesktop} />
    <ImgD src={imgTabletDesktop} />
  </Container>
)
