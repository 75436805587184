import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import mobileImg from '../images/profile/mobile.png'
import dekstopImg from '../images/profile/desktop.png'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 0 16px;
  margin-bottom: 26px;

  padding-top: 40px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 60px;

    padding-top: 54px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-bottom: 60px;

    padding-top: 60px;
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 21px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    top: 60px;

    p,
    h3 {
      max-width: 215px;
    }
  }
`

const MobileImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;

  @media (min-width: 768px) {
    max-width: 638px;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`

const DesktopImg = styled.img`
  display: none;

  @media (min-width: 1100px) {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
`

export const Profile = () => (
  <Container>
    <StyledTitle title="Профиль" subtitle="Большое и дружелюбное комьюнити по всему миру" />
    <MobileImg src={mobileImg} />
    <DesktopImg src={dekstopImg} />
  </Container>
)
