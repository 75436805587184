import React from 'react'
import styled from 'styled-components'

import closeIcon from './images/close.svg'
import errorImg from './images/error.svg'
import successImg from './images/success.svg'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(50, 48, 52, 0.4);
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 4815;
`

const ModalBox = styled.div`
  width: 304px;
  min-height: 400px;
  background: #ffffff;
  border-radius: 16px;
  padding: 64px 10px 46px 10px;
  position: relative;

  @media (min-width: 768px) {
    padding: 64px 64px 46px 64px;
    width: 620px;
  }
`

const CloseButton = styled.i`
  position: absolute;
  top: 26px;
  right: 26px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url('${closeIcon}');
  background-position: center center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 28px;
    right: 28px;
    width: 20px;
    height: 20px;
    background-size: 16px 16px;
  }
`

const Title = styled.h2`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #323034;
  margin-bottom: 16px;
`

const Descr = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #323034;
  margin-bottom: 40px;

  a {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #323034;
  }

  span {
    display: block;

    @media (min-width: 768px) {
      display: inline;
    }
  }
`

const Image = styled.img`
  display: block;
  margin: 0 auto;

  transform: scale(0.8);

  @media (min-width: 768px) {
    transform: scale(1);
  }
`

const typesContent = {
  success: {
    title: 'Спасибо за заявку!',
    descr: 'Мы свяжемся с вами <span>в течение дня</span>',
    image: successImg,
  },
  error: {
    title: 'Что-то пошло не так',
    descr: `<span>
      Попробуйте позже или отправьте <span>заявку на <a href="mailto:hello@heksray.ru">hello@heksray.ru</a> или по</span>
      телефону <a href="tel:+79163844628">+7 916 384-46-28</a>
    </span>`,
    image: errorImg,
  },
}

export const Modal = ({ isVisible, closeModal, type }) => (
  <Wrapper isVisible={isVisible} onClick={closeModal}>
    <ModalBox>
      <CloseButton onClick={closeModal} />
      <Title>{typesContent[type].title}</Title>
      <Descr
        dangerouslySetInnerHTML={{
          __html: typesContent[type].descr,
        }}
      />
      <Image src={typesContent[type].image} />
    </ModalBox>
  </Wrapper>
)
