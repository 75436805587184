import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { TagList } from '@/components/tags/TagList'

import roleImg from './images/role.svg'
import featuresImg from './images/features.svg'
import taskImg from './images/task.svg'
import areaImg from './images/area.svg'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 8px 16px 0;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    padding: 32px 80px 0;
    margin-bottom: 20px;
    overflow-x: hidden;
  }

  @media (min-width: 1100px) {
    overflow-x: visible;
    padding: 44px 0 0;
    margin-bottom: 139px;
  }
`

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`

const Title = styled.h1`
  margin: 0 auto;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323034;

  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 25px;
  }
`

const Subtitle = styled.p`
  margin: 0 auto;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #323034;

  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    max-width: 520px;

    margin-bottom: 24px;
  }
`

const StyledTags = styled(TagList)`
  margin: 0 auto;
  justify-content: center;
  position: relative;
  z-index: 2;

  margin-bottom: 32px;

  div:last-child {
    margin-right: 0;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 52px;
  }
`

const InfoBlocksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1100px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const InfoBlock = styled.li`
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: 24px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #323034;
  position: relative;

  p {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    @media (min-width: 768px) {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: 1100px) {
    width: 46%;
    margin-bottom: 80px;
    margin-right: 8%;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(n + 3) {
      margin-bottom: 0;
    }
  }
`

const InfoBlockImg = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;

  @media (min-width: 1100px) {
    width: 52px;
    height: 52px;
  }
`

const InfoBlockTitle = styled.h3`
  padding-left: 58px;
  height: 50px;
  display: flex;
  align-items: center;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323034;

  margin-bottom: 8px;

  @media (min-width: 768px) {
    padding-left: 66px;
    font-size: 16px;
    line-height: 18px;
    height: auto;

    margin-bottom: 16px;
    display: block;
  }

  @media (min-width: 1100px) {
    padding-left: 68px;
  }
`

const InfoBlockBody = styled.div`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323034;

  a {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    padding-left: 66px;
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 1100px) {
    padding-left: 68px;
  }
`

const FeaturesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  @media (min-width: 768px) {
    padding-left: 66px;
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 1100px) {
    padding-left: 68px;
  }
`

const FeatureItem = styled.li`
  margin: 0;
  padding: 0;
  padding-left: 15px;
  position: relative;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323034;

  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    top: 7px;
    left: 5px;
    background: #323034;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;

    &::before {
      top: 11px;
      left: 5px;
    }
  }
`

export function CaseHero({ logo, title, subtitle, tags, area, task, features, role }) {
  return (
    <Container>
      {logo ? <Logo src={logo} /> : ''}
      <Title h2>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <StyledTags tags={tags} />
      <InfoBlocksWrapper>
        <InfoBlock>
          <InfoBlockImg src={areaImg} alt="Область проекта" />
          <InfoBlockTitle>Область проекта</InfoBlockTitle>
          <InfoBlockBody
            dangerouslySetInnerHTML={{
              __html: area,
            }}
          />
        </InfoBlock>

        <InfoBlock>
          <InfoBlockImg src={taskImg} alt="Задача" />
          <InfoBlockTitle>Задача</InfoBlockTitle>
          <InfoBlockBody
            dangerouslySetInnerHTML={{
              __html: task,
            }}
          />
        </InfoBlock>

        <InfoBlock>
          <InfoBlockImg src={featuresImg} alt="Основой функционал" />
          <InfoBlockTitle>Основой функционал:</InfoBlockTitle>
          <FeaturesList>
            {features.map((item, index) => (
              <FeatureItem key={index}>{item}</FeatureItem>
            ))}
          </FeaturesList>
        </InfoBlock>

        <InfoBlock>
          <InfoBlockImg src={roleImg} alt="Наша роль в проекте" />
          <InfoBlockTitle>Наша роль в проекте</InfoBlockTitle>
          <InfoBlockBody
            dangerouslySetInnerHTML={{
              __html: role,
            }}
          />
        </InfoBlock>
      </InfoBlocksWrapper>
    </Container>
  )
}
