import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 44.5%;
  margin-bottom: 18px;

  &:nth-child(2n + 1) {
    margin-right: 11%;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1100px) {
    width: 22%;

    &:nth-child(2n + 1) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    &:nth-child(1) {
      margin-left: 13%;
      margin-right: 4%;
    }

    &:nth-child(2) {
      margin-right: 4%;
    }

    &:nth-child(3) {
      margin-right: 13%;
    }

    &:nth-child(n + 4) {
      margin-right: 4%;
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`

const MemberImage = styled.img`
  display: block;
  border-radius: 50%;
  width: 82%;
  margin: 0 auto;
  min-width: 120px;

  @media (min-width: 768px) {
    width: 100%;
  }
`

const MemberName = styled.h3`
  display: block;
  margin: 0 auto;
  margin-top: 8px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  width: 100%;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 28px;
  }
`

const MemberPosition = styled.p`
  display: block;
  margin: 0 auto;
  color: #898989;
  margin-top: 4px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  text-align: center;

  @media (min-width: 768px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
  }
`

export const TeamMember = ({ photo, name, position, alt }) => (
  <Container>
    <MemberImage src={photo} alt={alt} />
    <MemberName>{name}</MemberName>
    <MemberPosition>{position}</MemberPosition>
  </Container>
)
