import adv1Img from '../images/advantages/1.svg'
import adv2Img from '../images/advantages/2.svg'
import adv3Img from '../images/advantages/3.svg'

export const content = [
  {
    id: 1,
    title: 'Скорость',
    descr:
      'MVP содержит только необходимый и небольшой функционал, благодаря чему разработка занимает мало времени<br>Выйдите на рынок или покажите продукт инвесторам в кратчайшие сроки.',
    image: adv1Img,
  },
  {
    id: 2,
    title: 'Стоимость',
    descr:
      'До запуска продукта трудно понять, какие фичи будут востребованы пользователем, а какие нет.<br>Сфокусируйтесь и выделите бюджет только на самое необходимое.',
    image: adv2Img,
  },
  {
    id: 3,
    title: 'Адаптивность',
    descr: 'Соберите фидбек от пользователей и скорректируйте стратегию развития продукта при минимальных вложениях',
    image: adv3Img,
  },
]
