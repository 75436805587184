import * as React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { Services } from './content/services/Services'
import { Portfolio } from './content/portfolio/Portfolio'
import { WhyWe } from './content/why-we/WhyWe'
import { Team } from './content/team/Team'
import { LayoutWithForm } from '@/layouts/LayoutWithForm'
import { Hero } from './content/hero/Hero'

const BackgroundContainer = styled.div`
  background: #323034;
  padding-bottom: 60px;

  @media (min-width: 1100px) {
    background: linear-gradient(to bottom, #323034 93%, white 7%);
    padding-bottom: 0;
  }
`

export const LandingPage = () => (
  <LayoutWithForm pageName="MAIN">
    <Helmet>
      <title>HeksRay - Создаем приложения и сайты для вашего бизнеса</title>
    </Helmet>
    <Hero />
    <BackgroundContainer>
      <Portfolio />
      <Services />
    </BackgroundContainer>
    <WhyWe />
    <Team />
  </LayoutWithForm>
)
