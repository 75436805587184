import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'
import { PortfolioCase } from './portfolio-case/PortfolioCase'

import letovoLogoImage from './images/logos/letovo.png'
import letovoBackgroundImage from './images/backgrounds/5-desktop.png'
import letovoBackgroundImageTablet from './images/backgrounds/5-tablet.png'
import letovoBackgroundImageMobile from './images/backgrounds/5-mobile.png'

import cubingtimeLogoImage from './images/logos/cubingtime.svg'
import cubingtimeBackgroundImage from './images/backgrounds/1-desktop.png'
import cubingtimeBackgroundImageTablet from './images/backgrounds/1-tablet.jpg'
import cubingtimeBackgroundImageMobile from './images/backgrounds/1-mobile.jpg'

import opvBackgroundImage from './images/backgrounds/6-desktop.png'
import opvBackgroundImageTablet from './images/backgrounds/6-tablet.png'
import opvBackgroundImageMobile from './images/backgrounds/6-mobile.png'

import itrainyouLogoImage from './images/logos/itrainyou.svg'
import itrainyouBackgroundImage from './images/backgrounds/2-desktop.png'
import itrainyouBackgroundImageTablet from './images/backgrounds/2-tablet.png'
import itrainyouBackgroundImageMobile from './images/backgrounds/2-mobile.png'

import hanniLogoImage from './images/logos/hanni.svg'
import hanniBackgroundImage from './images/backgrounds/3-desktop.png'
import hanniBackgroundImageTablet from './images/backgrounds/3-tablet.jpg'
import hanniBackgroundImageMobile from './images/backgrounds/3-mobile.jpg'

import leavTraceBackgroundImage from './images/backgrounds/4-desktop.png'
import leavTraceBackgroundImageTablet from './images/backgrounds/4-tablet.png'
import leavTraceBackgroundImageMobile from './images/backgrounds/4-mobile.png'

const Container = styled(BlockContainer)`
  flex-direction: column;
  padding: 40px 16px 0;

  @media (min-width: 768px) {
    padding: 59px 79px 0;
  }

  @media (min-width: 1100px) {
    padding: 60px 0 0;
  }
`

const PortfolioList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0px;

  @media (min-width: 768px) {
    padding: 0;
    margin-top: 40px;
  }

  @media (min-width: 1100px) {
    padding: 0 10px;
    margin-top: 60px;
  }
`

export const Portfolio = () => (
  <Container id="portfolio">
    <BlockHeader white title={'Портфолио'} description={'наши последние проекты'} />
    <PortfolioList>
      <PortfolioCase
        logoImage={letovoLogoImage}
        backgroundImage={letovoBackgroundImage}
        backgroundImageTablet={letovoBackgroundImageTablet}
        backgroundImageMobile={letovoBackgroundImageMobile}
        description={`
        Портал для онлайн обучения с возможностью дистанционного участия в олимпиадах и сдаче экзаменов. Множество удобных функций для учителей по контролю успеваемости учеников.
        `}
        platforms={['Frontend', 'Backend', 'Management', 'Q&A']}
        link="/cases/letovo/"
      />
      <PortfolioCase
        logoImage={cubingtimeLogoImage}
        backgroundImage={cubingtimeBackgroundImage}
        backgroundImageTablet={cubingtimeBackgroundImageTablet}
        backgroundImageMobile={cubingtimeBackgroundImageMobile}
        description={`
        CubingTime – приложение с уникальным таймером для спидкубинга, которым пользуется весь мир.
        За пару недель приложение на платформах установило суммарно более 15.000 пользователей
        `}
        rating={{
          rate: '4.2+',
          descr: 'оценка приложения в магазинах при >20.000 скачиваний',
        }}
        platforms={['Design', 'iOS', 'Android', 'Management']}
        link="/cases/cubing/"
      />
      <PortfolioCase
        logoText="Платформа 3D туров по квартирам"
        backgroundImage={opvBackgroundImage}
        backgroundImageTablet={opvBackgroundImageTablet}
        backgroundImageMobile={opvBackgroundImageMobile}
        description={'Веб-приложение, в котором клиенты могут смотреть 3D туры по своей будущей квартире'}
        platforms={['Backend', 'Management', 'Q&A']}
        link="/cases/offplanvisuals/"
      />
      <PortfolioCase
        logoImage={itrainyouLogoImage}
        backgroundImage={itrainyouBackgroundImage}
        backgroundImageTablet={itrainyouBackgroundImageTablet}
        backgroundImageMobile={itrainyouBackgroundImageMobile}
        description={
          'iTrainYou — информационная мультифункциональная платформа' +
          ' для работы коучей и клиентов и оптимизации жизненных процессов.'
        }
        platforms={['Design', 'Frontend', 'Backend', 'Management']}
        link="/cases/itrain/"
      />
      <PortfolioCase
        logoImage={hanniLogoImage}
        backgroundImage={hanniBackgroundImage}
        backgroundImageTablet={hanniBackgroundImageTablet}
        backgroundImageMobile={hanniBackgroundImageMobile}
        description={
          'ITrain Phonetics - уникальное приложение, предназначенное для изучения фонетики китайского языка.' +
          ' Приложение незаменимо для начинающих изучение китайского языка и полезно для продолжающих.'
        }
        platforms={['Design', 'iOS', 'Android']}
        link="/cases/hanni/"
      />
      <PortfolioCase
        logoText="Проект цифрового бессмертия"
        backgroundImage={leavTraceBackgroundImage}
        backgroundImageTablet={leavTraceBackgroundImageTablet}
        backgroundImageMobile={leavTraceBackgroundImageMobile}
        description={'Сайт, который позволяет пользователям оставить <span>след о себе.</span>'}
        platforms={['Design', 'Frontend', 'Backend', 'Management']}
        link="/cases/leavtrace/"
      />
    </PortfolioList>
  </Container>
)
