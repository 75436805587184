export const routeNames = {
  landing: () => '/',
  business: () => '/business',
  getTeam: () => '/get-team',
  mvp: () => '/mvp',
  cubingTime: () => '/cases/cubing',
  iTrain: () => '/cases/itrain',
  hanni: () => '/cases/hanni',
  leavTrace: () => '/cases/leavtrace',
  letovo: () => '/cases/letovo',
  offPlanVisuals: () => '/cases/offplanvisuals',
}
