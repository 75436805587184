import styled from 'styled-components'

export const BlockContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;

  @media (min-width: 1100px) {
    max-width: 1080px;
  }
`
