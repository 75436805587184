import React from 'react'
import styled from 'styled-components'

import { HashLink } from 'react-router-hash-link'

import arrowImage from './images/arrow.svg'

const Container = styled(HashLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 145px;
  height: 20px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 210px;
    height: 48px;
  }
`

const ButtonText = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 18px;
  }
`

const ArrowImageContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 48px;
    height: 48px;
  }
`

export const StyledArrowImage = styled.img.attrs({ src: arrowImage })`
  width: 12px;

  @media (min-width: 768px) {
    width: 19px;
  }
`

export const OpenCaseButton = ({ className, to }) => (
  <Container className={className} to={to}>
    <ButtonText>Посмотреть проект</ButtonText>
    <ArrowImageContainer>
      <StyledArrowImage />
    </ArrowImageContainer>
  </Container>
)
