import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import img from '../images/choose-coach/choose-coach.png'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 0 16px;
  margin-bottom: 128px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 178px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-bottom: 120px;
  }
`

const StyledTitle = styled(Title)`
  position: absolute;
  top: -90px;
  right: 16px;

  p,
  h3 {
    width: 216px;
  }

  @media (min-width: 768px) {
    top: -103px;
    right: 80px;

    p,
    h3 {
      width: 282px;
    }
  }

  @media (min-width: 1100px) {
    top: 130px;
    right: 160px;

    p,
    h3 {
      width: 232px;
    }
  }
`

const Img = styled.img`
  display: block;
  margin: 0;
  width: 100%;

  @media (min-width: 768px) {
    width: 540px;
  }

  @media (min-width: 1100px) {
    width: 680px;
  }
`

export const ChooseCoach = () => (
  <Container>
    <StyledTitle
      title="Выбор коуча"
      subtitle="Коучи самостоятельно выбирают удобное для себя расписание. А клиенты быстро выбирают коуча на основе отзывов и рейтингов."
    />
    <Img src={img} />
  </Container>
)
