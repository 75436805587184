export const content = [
  {
    id: 1,
    title: 'S',
    price: '900 000 ₽',
    time: '30 дней',
    services: ['каталог', 'информационное приложение', 'zenly-like приложения'],
  },
  {
    id: 2,
    title: 'M',
    price: '1 600 000 ₽',
    time: '60 дней',
    services: ['небольшая социальная сеть', 'tinder-like приложение'],
  },
  {
    id: 3,
    title: 'L',
    price: '2 300 000 ₽',
    time: '90 дней',
    services: ['маркетплейс', 'прототип большого приложения'],
  },
]
