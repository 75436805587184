import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;

  @media (min-width: 1100px) {
    width: 225px;
  }
`

const SeparatingLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: white;
  margin-top: 8px;
`

const ListContainer = styled.div``

const ItemContainer = styled.div`
  margin-top: 12px;
`

const Text = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
`

export const NavigationList = ({ title, children, textItems, className }) => {
  return (
    <Container className={className}>
      <Text>{title}</Text>
      <SeparatingLine />
      <ListContainer>
        {Array.isArray(children) ? (
          children.map((child, index) => <ItemContainer key={index}>{child}</ItemContainer>)
        ) : (
          <ItemContainer>{children}</ItemContainer>
        )}
      </ListContainer>
    </Container>
  )
}
