import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import imgMobileTabletDesktop from '../images/bio/mobile-tablet-desktop.png'

const Container = styled(BlockContainer)`
  display: flex;
  position: relative;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const StyledTitle = styled(Title)`
  width: 254px;

  margin-left: 0;
  padding-bottom: 448px;

  @media (min-width: 768px) {
    width: 248px;

    margin-left: auto;
    padding-bottom: 428px;
  }

  @media (min-width: 1100px) {
    width: 256px;

    margin-left: auto;
    margin-right: 120px;
    padding-bottom: 459px;
  }
`

const Img = styled.img`
  position: absolute;
`

const ImgM = styled(Img)`
  display: none;

  @media (max-width: 767px) {
    display: block;

    width: 273px;

    bottom: 70px;
    right: -36px;
  }
`

const ImgT = styled(Img)`
  display: none;

  @media (min-width: 768px) and (max-width: 1099px) {
    display: block;

    width: 381px;

    top: -170px;
    left: 70px;
  }
`

const ImgD = styled(Img)`
  display: none;

  @media (min-width: 1100px) {
    display: block;

    width: 535px;

    top: -380px;
    left: 50px;
  }
`

export const Bio = () => (
  <Container>
    <StyledTitle title="Биография" subtitle="Есть возможность писать свою биографию с добавлениием фотографий" />
    <ImgM src={imgMobileTabletDesktop} />
    <ImgT src={imgMobileTabletDesktop} />
    <ImgD src={imgMobileTabletDesktop} />
  </Container>
)
