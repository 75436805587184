import React, { useState } from 'react'
import styled from 'styled-components'

import { content } from './questions/content'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

const Container = styled(BlockContainer)`
  display: none;
  padding: 0 16px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    display: block;
    padding: 0;
    margin-bottom: 96px;
  }
`

const List = styled.ul`
  margin: 0 auto;
  padding: 0;
  list-style: none;
  width: 563px;
  margin-top: 36px;
`

const ListItem = styled.li`
  width: 100%;
  background: #ffffff;
  padding: 24px 0;
  padding-right: 20px;
  position: relative;
  cursor: pointer;

  h3 {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #323034;
  }

  p {
    margin-top: 16px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #323034;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #323034;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`

const Plus = styled.i`
  display: block;
  position: absolute;
  top: 27px;
  right: 3px;
  width: 14px;
  height: 14px;

  &::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: #323034;
  }

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 14px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #323034;
    opacity: ${props => (props.isOpen ? '0' : '1')};
  }
`

export const Questions = () => {
  const [openedQuestions, setOpenedQuestions] = useState([0])

  const toggleQuestion = index => {
    let newQuestions = [...openedQuestions]

    if (openedQuestions.indexOf(index) === -1) {
      newQuestions.push(index)
    } else {
      newQuestions = newQuestions.filter(item => item !== index)
    }

    setOpenedQuestions([...newQuestions])
  }

  return (
    <Container>
      <BlockHeader title={'У Вас остались вопросы?'} description={'Вот ответы на самые распространенные вопросы'} />
      <List>
        {content.map((item, index) => (
          <ListItem
            key={item.id}
            onClick={() => {
              toggleQuestion(index)
            }}
          >
            <h3>{item.question}</h3>
            {openedQuestions.includes(index) ? <p>{item.answer}</p> : ''}
            <Plus isOpen={openedQuestions.includes(index)} />
          </ListItem>
        ))}
      </List>
    </Container>
  )
}
