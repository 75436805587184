import * as React from 'react'
import styled from 'styled-components'

import { HashLink } from 'react-router-hash-link'

import vkImage from './images/vk-image.svg'
import instagramImage from './images/instagram-image.svg'
import facebookImage from './images/facebook-image.svg'

const Content = styled.div`
  position: absolute;
  top: ${props => (props.isModalOpen ? '48px' : 'calc(-100vh - 48px)')};
  left: 0;
  width: 100%;
  min-height: calc(100vh - 48px);
  background: white;
  z-index: 99;

  transition: all 0.3s ease;

  padding: 32px 16px 24px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    top: ${props => (props.isModalOpen ? '64px' : 'calc(-100vh - 64px)')};
    min-height: calc(100vh - 64px);

    padding: 116px 64px 40px;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  max-height: 404px;
`

const BigItem = styled(HashLink)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #323034;
  padding-left: 16px;

  position: relative;

  &:not(:first-child) {
    margin-top: 57px;
  }

  

  &::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #F2F2F2;
  }

  &:nth-child(2) {
    &::after {
      bottom: -146px;
    }
}
`

const SmallItem = styled(HashLink)`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #323034;
  padding-left: 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`

const Bottom = styled.div`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
`

const Email = styled.a`
  display: block;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Socials = styled.div`
  display: flex;
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }

  /* temp hide socials */
  display: none;
`

const Social = styled.a`
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 24px;
  }
`

const SocialIcon = styled.img`
  display: block;
  width: 32px;
  height: 32px;

  @media (min-width: 768px) {
    width: 44px;
    height: 44px;
  }
`

export const Modal = ({ isModalOpen, className, toggleModal }) => (
  <Content isModalOpen={isModalOpen} className={className}>
    <Menu>
      <BigItem to="/#portfolio" onClick={() => toggleModal()}>
        Портфолио
      </BigItem>
      <BigItem to="/#services" onClick={() => toggleModal()}>
        Услуги
      </BigItem>
      <SmallItem to="/business" onClick={() => toggleModal()}>
        Стартап и бизнес
      </SmallItem>
      <SmallItem to="/mvp" onClick={() => toggleModal()}>
        MVP
      </SmallItem>
      <SmallItem to="/get-team" onClick={() => toggleModal()}>
        Аренда команды
      </SmallItem>
      <BigItem to="/#why-we" onClick={() => toggleModal()}>
        Почему мы
      </BigItem>
      <BigItem to="/#team" onClick={() => toggleModal()}>
        Команда
      </BigItem>
    </Menu>
    <Bottom>
      <Email href={'mailto:cust@heksray.com'}>cust@heksray.com</Email>
      <Socials>
        <Social>
          <SocialIcon src={facebookImage} />
        </Social>
        <Social>
          <SocialIcon src={instagramImage} />
        </Social>
        <Social>
          <SocialIcon src={vkImage} />
        </Social>
      </Socials>
    </Bottom>
  </Content>
)
