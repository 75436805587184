import React, { useState } from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Button } from '@/components/button/Button'
import { Input, TextArea } from '@/components/input/Input'

const Container = styled(BlockContainer)`
  padding: 0 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: 768px) {
    padding: 0 84px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
`

const Subtitle = styled.p`
  margin-top: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 288px;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 40px;

  ${Input}:not(:first-child), ${TextArea}:not(:first-child) {
    margin-top: 20px;
  }

  @media (min-width: 768px) {
    width: 600px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 0;
  }
`

const Label = styled.label`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const TabList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 172px;

  margin-top: 16px;
  margin-bottom: 4px;

  @media (min-width: 768px) {
  }
`

const StyledTab = styled.div`
  width: 44px;
  height: 44px;

  box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.2);
  border-radius: 4px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => (props.selected ? 'white' : '#323034')};
  background-color: ${props => (props.selected ? '#323034' : 'white')};

  @media (min-width: 768px) {
  }
`

const SubmitButton = styled(Button)`
  padding: 9px 12px;
  font-size: 14px;
  line-height: 18px;
  align-self: center;
  margin-top: 20px;

  cursor: ${props => (props.isActive ? 'pointer' : 'default')};
  pointer-events: ${props => (props.isActive ? 'auto' : 'none')};

  @media (min-width: 768px) {
    padding: 10px 50px;
    font-size: 22px;
    line-height: 28px;
  }
`

const ExtraText = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323034;

  > span.mobile-desktop {
    display: block;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    max-width: 420px;

    > span.mobile-desktop {
      display: inline;
    }
  }

  @media (min-width: 1440px) {
    position: absolute;
    top: 216px;
    left: -100px;
    text-align: left;
    max-width: 100%;

    > span.mobile-desktop {
      display: block;
    }
  }
`

export const LetsWork = ({ openModal }) => {
  const [s, setS] = useState(false)
  const [m, setM] = useState(false)
  const [l, setL] = useState(false)

  const [nameValue, setNameValue] = useState('')
  const [telValue, setTelValue] = useState('')
  const [descrValue, setDescrValue] = useState('')

  const handleChange = (e, field) => {
    switch (field) {
      case 'name':
        setNameValue(e.target.value)
        break
      case 'tel':
        setTelValue(e.target.value)
        break
      case 'descr':
        setDescrValue(e.target.value)
        break
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const mvpOption = s ? 'SMALL' : m ? 'MEDIUM' : l ? 'LARGE' : ''

    const bodyObject = {
      client_name: nameValue,
      site_page: 'MVP',
      mvp_option: mvpOption,
      services: null,
      price_from: null,
      price_to: null,
      client_contact: telValue,
      project_description: descrValue,
    }

    const jsonBody = JSON.stringify(bodyObject)

    fetch('https://heksray.com/api/v1/orders/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    })
      .then(res => {
        setS(false)
        setM(false)
        setL(false)

        setNameValue('')
        setTelValue('')
        setDescrValue('')

        openModal('success')
      })
      .catch(error => {
        console.error('Error:', error)

        openModal('error')
      })
  }

  return (
    <Container id="letswork">
      <Title>Закажите MVP у нас!</Title>
      <Subtitle>Нам интересно работать вместе с вами</Subtitle>
      <Form onSubmit={e => handleSubmit(e)}>
        <Label>Выберите тариф</Label>
        <TabList>
          <StyledTab
            onClick={() => {
              setS(!s)
              setM(false)
              setL(false)
            }}
            selected={s}
          >
            S
          </StyledTab>
          <StyledTab
            onClick={() => {
              setS(false)
              setM(!m)
              setL(false)
            }}
            selected={m}
          >
            M
          </StyledTab>
          <StyledTab
            onClick={() => {
              setS(false)
              setM(false)
              setL(!l)
            }}
            selected={l}
          >
            L
          </StyledTab>
        </TabList>
        <Input
          type="text"
          name="name"
          placeholder="Как вас зовут?"
          value={nameValue}
          onChange={e => handleChange(e, 'name')}
        />
        <Input
          type="text"
          name="contacts"
          placeholder="Ваш телефон или электронная почта"
          value={telValue}
          onChange={e => handleChange(e, 'tel')}
        />
        <TextArea
          name="project-description"
          placeholder="Напишите вкратце о своем проекте"
          value={descrValue}
          onChange={e => handleChange(e, 'descr')}
        />
        <SubmitButton isActive={nameValue && telValue && descrValue}>Отправить</SubmitButton>
      </Form>
      <ExtraText>
        <span class="mobile-desktop">Запишитесь на бесплатную</span>{' '}
        <span class="mobile-desktop">консультацию и узнайте стоимость</span> и сроки разработки вашего проекта
      </ExtraText>
    </Container>
  )
}
