import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import desktopImg from './images/pc.svg'
import mobileImg from './images/mobile.svg'
import noteImg from './images/note.svg'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  margin-top: 80px;

  @media (min-width: 768px) {
    margin-top: 120px;
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    margin-top: 60px;
  }

  @media (min-width: 1100px) {
    margin-top: 100px;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
`

const cards = [
  <Card
    title={'Сайты'}
    body={'Создаем красивые<br>и производительные сайты,<br>корректно работающие<br>во всех браузерах'}
    img={desktopImg}
    key={1}
  />,
  <Card
    title={'Мобильное приложение'}
    body={
      'Быстро разрабатываем<br>красивые<br>и производительные<br>приложения сразу под iOS<br>и Android при помощи Flutter'
    }
    img={mobileImg}
    key={2}
  />,
  <Card
    title={'Другие услуги'}
    body={
      'Проводим аудиты<br>и консультации, помогаем<br>с подбором команды,<br>выбором технологий<br>и архитектуры для проекта'
    }
    img={noteImg}
    key={3}
  />,
]

export const HowCanWeBeUseful = () => (
  <Container>
    <BlockHeader title={'Чем мы можем быть полезны?'} />
    <Wrapper>{cards}</Wrapper>
  </Container>
)

const CardWrapper = styled.div`
  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.2);
  border-radius: 16px;
  width: 288px;
  padding: 24px 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #323034;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #323034;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (min-width: 1100px) {
      width: 240px;
    }
  }

  @media (min-width: 768px) {
    width: 608px;
    padding: 24px 94px 32px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 1100px) {
    width: 320px;
    padding: 26px 40px 32px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const CardImg = styled.img`
  margin-bottom: 36px;
`

function Card({ img, title, body }) {
  return (
    <CardWrapper>
      <CardImg src={img} />
      <h3>{title}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
    </CardWrapper>
  )
}
