import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import reactLogo from './images/stack/frontend/react.svg'
import tsLogo from './images/stack/frontend/typescript.svg'
import reduxLogo from './images/stack/frontend/redux.svg'
import effectorLogo from './images/stack/frontend/effector.png'

import pythonLogo from './images/stack/backend/python.svg'
import djangoLogo from './images/stack/backend/django.svg'
import postgresqlLogo from './images/stack/backend/postgresql.svg'
import dockerLogo from './images/stack/backend/docker.svg'

import dartLogo from './images/stack/mobile/dart.svg'
import flutterLogo from './images/stack/mobile/flutter.svg'
import mobxLogo from './images/stack/mobile/mobx.svg'
import riverpodLogo from './images/stack/mobile/riverpod.svg'

import figmaLogo from './images/stack/design/figma.svg'
import illustratorLogo from './images/stack/design/illustrator.svg'
import photoshopLogo from './images/stack/design/photoshop.svg'
import aeLogo from './images/stack/design/ae.svg'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const List = styled.ul`
  margin: 0 auto;
  padding: 0;
  margin-top: 40px;
  list-style: none;
  width: 288px;

  @media (min-width: 768px) {
    margin-top: 60px;
    width: 500px;
  }

  @media (min-width: 1100px) {
    width: 1064px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
  }
`

const Item = styled.li`
  margin: 0;
  padding: 20px 36px;
  width: 100%;

  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.2);
  border-radius: 16px;

  @media (max-width: 1099px) {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1100px) {
    width: 500px;
    margin-right: 64px;
    margin-bottom: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:last-child(-n + 3) {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #323034;
  margin-bottom: 20px;
`

const Langs = styled.ul`
  margin: 0 auto;
  padding: 0;
  list-style: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`

const Lang = styled.li`
  margin: 0;
  width: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    margin-bottom: 14px;
    margin-right: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(n + 3) {
      margin-bottom: 0;
    }
  }
`

const Logo = styled.img`
  height: 60px;
  display: block;
  margin-bottom: 12px;
`

const Name = styled.h4`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323034;
`

export const Stack = () => (
  <Container>
    <BlockHeader title={'Мы используем'} />
    <List>
      <Item>
        <Title>Frontend</Title>
        <Langs>
          <Lang>
            <Logo src={reactLogo} />
            <Name>React</Name>
          </Lang>
          <Lang>
            <Logo src={tsLogo} />
            <Name>Typescript</Name>
          </Lang>
          <Lang>
            <Logo src={reduxLogo} />
            <Name>Redux</Name>
          </Lang>
          <Lang>
            <Logo src={effectorLogo} />
            <Name>Effector</Name>
          </Lang>
        </Langs>
      </Item>
      <Item>
        <Title>Backend</Title>
        <Langs>
          <Lang>
            <Logo src={pythonLogo} />
            <Name>Python</Name>
          </Lang>
          <Lang>
            <Logo src={djangoLogo} />
            <Name>Django</Name>
          </Lang>
          <Lang>
            <Logo src={postgresqlLogo} />
            <Name>ProstgreSQL</Name>
          </Lang>
          <Lang>
            <Logo src={dockerLogo} />
            <Name>Docker</Name>
          </Lang>
        </Langs>
      </Item>
      <Item>
        <Title>Mobile</Title>
        <Langs>
          <Lang>
            <Logo src={dartLogo} />
            <Name>Dart</Name>
          </Lang>
          <Lang>
            <Logo src={flutterLogo} />
            <Name>Flutter</Name>
          </Lang>
          <Lang>
            <Logo src={mobxLogo} />
            <Name>MobX</Name>
          </Lang>
          <Lang>
            <Logo src={riverpodLogo} />
            <Name>Riverpod</Name>
          </Lang>
        </Langs>
      </Item>
      <Item>
        <Title>Design</Title>
        <Langs>
          <Lang>
            <Logo src={figmaLogo} />
            <Name>Figma</Name>
          </Lang>
          <Lang>
            <Logo src={illustratorLogo} />
            <Name>Illustrator</Name>
          </Lang>
          <Lang>
            <Logo src={photoshopLogo} />
            <Name>Photoshop</Name>
          </Lang>
          <Lang>
            <Logo src={aeLogo} />
            <Name>After Effects</Name>
          </Lang>
        </Langs>
      </Item>
    </List>
  </Container>
)
