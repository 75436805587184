import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Modal } from '@/components/modal/Modal'
import { MainLayout } from '@/layouts/MainLayout'
import { Hero } from '@/components/common/hero/Hero'
import { WhatIsIt } from './content/WhatIsIt'
import { Advantages } from './content/Advantages'
import { Developers } from './content/Developers'
import { Stack } from './content/Stack'
import { LetsWork } from './content/LetsWork'
import { OtherServices } from '@/components/common/other-services/OtherServices'

import mainImage from './content/images/hero-image.svg'
import mvpImage from './content/images/mvp.svg'
import businessImage from './content/images/business.svg'

export const GetTeamPage = () => {
  const [isModalOpen, setIsOpenModal] = useState(false)
  const [type, setType] = useState('error')

  const openModal = typeInc => {
    setType(typeInc)
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setIsOpenModal(false)
  }

  return (
    <MainLayout>
      <Modal isVisible={isModalOpen} openModal={openModal} closeModal={closeModal} type={type} />
      <Helmet>
        <title>Аренда команды - HeksRay</title>
      </Helmet>
      <Hero
        mainImage={mainImage}
        mainText={'Аренда команды'}
        subTitle={'Если вам нужна команда на длительное время, у нас есть команда профессионалов!'}
        buttonText={'Выбрать тариф'}
        titleMaxWidth={400}
        subTitleMaxWidth={340}
      />
      <WhatIsIt />
      <Advantages />
      {/*<Developers />*/}
      <Stack />
      <LetsWork openModal={openModal} />
      <OtherServices
        title={'Наши другие услуги'}
        services={[
          {
            title: 'Стартап и Бизнес',
            description: 'Поможем с разработкой сайтов и приложений для больших и сложных проектов',
            image: businessImage,
            key: 'business',
            link: '/business',
          },
          {
            title: 'MVP',
            description: 'Разработаем упрощенную версию проекта для быстрой проверки гипотез с минимальными затратами',
            image: mvpImage,
            key: 'mvp',
            link: '/mvp',
          },
        ]}
      />
    </MainLayout>
  )
}
