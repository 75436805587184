import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

import mvpSmallImg from './images/what-is/mvp-small.svg'
import mvpBigImg from './images/what-is/mvp-big.svg'

const MainWrapper = styled.div`
  margin-bottom: 60px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 40px;
  }
`

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  position: relative;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const UniqueWrapper = styled.div`
  width: 100%;
  margin-top: 24px;

  @media (min-width: 768px) {
    background: #fbfbfb;
    margin-top: 60px;
    padding: 32px 0 98px;
  }

  @media (min-width: 1100px) {
    padding: 32px 0 66px;
  }
`

const MobileImage = styled.img`
  display: block;
  margin: 0 auto;

  @media (min-width: 768px) {
    display: none;
  }
`

const DesktopImage = styled.img`
  display: none;

  @media (min-width: 768px) {
    display: block;
    height: 196px;
  }

  @media (min-width: 1100px) {
    display: block;
    height: 178px;
  }
`

const Dash = styled.i`
  display: none;

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    top: 99px;
    left: 198px;

    width: 29px;
    height: 7px;
    background-color: #323034;
  }

  @media (min-width: 1100px) {
    width: 55px;

    top: 89px;
    left: 168px;
  }
`

const Text1 = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #323034;

  margin-bottom: 47px;

  @media (min-width: 768px) {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    margin-bottom: 0;

    position: absolute;
    width: 175px;
    top: 101px;
    left: 250px;
  }

  @media (min-width: 1100px) {
    width: 277px;
    top: 91px;
    left: 280px;
  }
`

const Text2 = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 18px;

    position: absolute;
    width: 224px;
    top: 140px;
    left: 450px;
  }

  @media (min-width: 1100px) {
    width: 375px;
    top: 113px;
    left: 610px;
  }
`

export const WhatIsIt = () => (
  <MainWrapper>
    <Container>
      <BlockHeader title={'Что это такое?'} />
    </Container>
    <UniqueWrapper>
      <Container>
        <MobileImage src={mvpSmallImg} />
        <DesktopImage src={mvpBigImg} />
        <Dash />
        <Text1>продукт, обладающей минимальным и самым необходимым для работы функционалом.</Text1>
        <Text2>
          MVP − необходимый шаг для успешного запуска продукта, т.к. он позволяет протестировать идею и собрать фидбек
          от клиентов при минимальных временных и денежных затратах.
        </Text2>
      </Container>
    </UniqueWrapper>
  </MainWrapper>
)
