import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import imgMobileTabletDesktop from '../images/adaptive/mobile-tablet-desktop.png'

const Container = styled(BlockContainer)`
  display: flex;
  position: relative;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const StyledTitle = styled(Title)`
  width: 211px;

  margin-left: auto;
  padding-bottom: 342px;

  text-align: center;

  margin-right: auto;

  @media (min-width: 768px) {
    width: 211px;

    margin-left: 0;
    padding-bottom: 668px;

    text-align: left;

    margin-right: 0;
  }

  @media (min-width: 1100px) {
    width: 186px;

    margin-left: auto;
    padding-bottom: 773px;

    text-align: left;

    margin-right: 120px;
  }
`

const Img = styled.img`
  position: absolute;
`

const ImgM = styled(Img)`
  display: none;

  @media (max-width: 767px) {
    display: block;

    width: 290px;

    bottom: 90px;
    right: 50%;
    transform: translateX(50%);
  }
`

const ImgT = styled(Img)`
  display: none;

  @media (min-width: 768px) and (max-width: 1099px) {
    display: block;

    width: 704px;

    bottom: 70px;
    left: 20px;
  }
`

const ImgD = styled(Img)`
  display: none;

  @media (min-width: 1100px) {
    display: block;

    width: 919px;

    bottom: 85px;
    right: 60px;
  }
`

export const Adaptive = () => (
  <Container>
    <StyledTitle title="Есть удобные адаптивные версии" />
    <ImgM src={imgMobileTabletDesktop} />
    <ImgT src={imgMobileTabletDesktop} />
    <ImgD src={imgMobileTabletDesktop} />
  </Container>
)
