import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Modal } from '@/components/modal/Modal'
import { MainLayout } from '@/layouts/MainLayout'
import { Hero } from '@/components/common/hero/Hero'
import { ForWhom } from './content/ForWhom'
import { HowCanWeBeUseful } from './content/HowCanWeBeUseful'
import { HowWeWorking } from './content/HowWeWorking'
import { LetsWork } from './content/LetsWork'
import { OtherServices } from '@/components/common/other-services/OtherServices'

import mainImage from './content/images/hero-image.svg'
import mvpImage from './content/images/mvp.svg'
import arendaImage from './content/images/arenda.svg'

export const BusinessPage = () => {
  const [isModalOpen, setIsOpenModal] = useState(false)
  const [type, setType] = useState('error')

  const openModal = typeInc => {
    setType(typeInc)
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setIsOpenModal(false)
  }

  return (
    <MainLayout>
      <Modal isVisible={isModalOpen} openModal={openModal} closeModal={closeModal} type={type} />
      <Helmet>
        <title>Стартап и бизнес - HeksRay</title>
      </Helmet>
      <Hero
        mainImage={mainImage}
        mainText={'Вы уже понимаете, какая у вас аудитория, и хотите большое приложение?'}
        subTitle={'Если вам нужен сайт или приложение, то мы вам поможем'}
        buttonText={'Заказать'}
        titleMaxWidth={400}
        subTitleMaxWidth={310}
      />
      <ForWhom />
      <HowCanWeBeUseful />
      <HowWeWorking />
      <LetsWork openModal={openModal} />
      <OtherServices
        title={'Наши другие услуги'}
        services={[
          {
            title: 'MVP',
            description: 'Разработаем упрощенную версию проекта для быстрой проверки гипотез с минимальными затратами',
            image: mvpImage,
            key: 'mvp',
            link: '/mvp',
          },
          {
            title: 'Аренда команды',
            description: 'Предоставим выделенную команду под ваш проект с помесячной оплатой',
            image: arendaImage,
            key: 'arenda',
            link: '/get-team',
          },
        ]}
      />
    </MainLayout>
  )
}
