import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 282px;
  }
`

const Title = styled.h2`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323034;

  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Descr = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323034;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Image = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 260px;

  @media (min-width: 768px) {
    margin: 0;
    max-width: 282px;
  }

  @media (min-width: 1100px) {
    max-width: 455px;
  }
`

const Container = styled.li`
  margin: 0;
  padding: 0;

  margin-bottom: 55px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(1) {
      > ${Content} {
        width: 320px;
      }
    }
  }

  @media (min-width: 1100px) {
    justify-content: center;

    > ${Image} {
      margin-left: 80px;
    }

    &:nth-child(1) {
      > ${Content} {
        width: 430px;
      }
    }
  }
`

const ContainerReverse = styled(Container)`
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }

  @media (min-width: 1100px) {
    > ${Image} {
      margin-right: 80px;
      margin-left: 0;
    }
  }
`

export const ListItem = ({ title, descr, img }) => (
  <Container>
    <Content>
      <Title>{title}</Title>
      <Descr>{descr}</Descr>
    </Content>
    <Image src={img} />
  </Container>
)

export const ListItemReverse = ({ title, descr, img }) => (
  <ContainerReverse>
    <Content>
      <Title>{title}</Title>
      <Descr>{descr}</Descr>
    </Content>
    <Image src={img} />
  </ContainerReverse>
)
