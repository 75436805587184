import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import img from '../images/stats/mobile-desktop.png'

const Container = styled(BlockContainer)`
  display: block;
  position: relative;
  padding: 0 16px;
  padding-top: 40px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    padding: 0 80px;
    padding-top: 60px;
    margin-bottom: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    padding-top: 70px;
    margin-bottom: 120px;
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    top: 87px;

    p,
    h3 {
      max-width: 282px;
    }
  }
`

const Img = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 288px;

  @media (min-width: 768px) {
    max-width: 703px;
  }

  @media (min-width: 1100px) {
    margin: 0 auto;
    max-width: 100%;
  }
`

export const Stats = () => (
  <Container>
    <StyledTitle title="Статистика" subtitle="Отслеживание прогресса изучения различных тонов" />
    <Img src={img} />
  </Container>
)
