export const content = [
  {
    id: 1,
    question: 'Почему разработка MVP важна?',
    answer: 'Почему разработка MVP важна?',
  },
  {
    id: 2,
    question: 'Сколько времени занимает разработка MVP?',
    answer: 'Сколько времени занимает разработка MVP?',
  },
  {
    id: 3,
    question: 'Кто владеет ресурсами и кодом моего приложения MVP?',
    answer: 'Кто владеет ресурсами и кодом моего приложения MVP?',
  },
  {
    id: 4,
    question: 'Будете ли вы разрабатывать MVP для справедливости в моем стартапе?',
    answer: 'Будете ли вы разрабатывать MVP для справедливости в моем стартапе?',
  },
]
