import * as React from 'react'
import styled from 'styled-components'

const Line = styled.div`
  height: 1px;
  flex-grow: 1;
  background-color: ${props => (props.white ? 'white' : '#323034')};
  transform: translateY(1px);

  @media (min-width: 768px) {
    height: 2px;
  }
`

const Round = styled.div`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${props => (props.white ? 'white' : '#323034')};
  transform: translateY(1px);

  @media (min-width: 768px) {
    width: 24px;
    height: 24px;
  }
`

export const Ray = ({ white }) => {
  return (
    <>
      <Line white={white} />
      <Round white={white} />
    </>
  )
}
