import React, { useState } from 'react'

import { Modal } from '@/components/modal/Modal'
import { Topbar } from '@/components/common/topbar/Topbar'
import { OrderForm } from '@/components/common/order-form/OrderForm'
import { Footer } from '@/components/common/footer/Footer'

export const LayoutWithForm = ({ pageName, children }) => {
  const [isModalOpen, setIsOpenModal] = useState(false)
  const [type, setType] = useState('error')

  const openModal = typeInc => {
    setType(typeInc)
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Modal isVisible={isModalOpen} openModal={openModal} closeModal={closeModal} type={type} />
      <Topbar />

      <main>
        {children}

        <OrderForm pageName={pageName} openModal={openModal} />
      </main>
      <Footer />
    </>
  )
}
