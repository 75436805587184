import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;
  margin-top: 40px;
  width: 280px;

  @media (min-width: 768px) {
    margin-top: 60px;
    width: 536px;
  }
`

const ListItem = styled.li`
  margin: 0;
  padding: 0;
  width: 100%;
  padding-left: 68px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &::before {
    content: '1';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background: #f3f3f3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #323034;
  }

  &:nth-child(2) {
    &::before {
      content: '2';
    }
  }
  &:nth-child(3) {
    &::before {
      content: '3';
    }
  }

  h3 {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #323034;
    margin-bottom: 12px;
  }

  p {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #323034;
  }

  @media (min-width: 768px) {
    padding-left: 131px;

    &:not(:last-child) {
      margin-bottom: 43px;
    }

    &::before {
      width: 75px;
      height: 75px;
      font-size: 50px;
      line-height: 50px;
    }

    h3 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      min-height: 36px;
    }
  }
`

export const HowItWorks = () => (
  <Container>
    <BlockHeader title={'Как это работает?'} />
    <List>
      <ListItem>
        <h3>Сбор требований</h3>
        <p>Обсуждаем с вами идею вашего продукта. Составляем список требований</p>
      </ListItem>
      <ListItem>
        <h3>Проектирование MVP</h3>
        <p>Анализируем требования и оставляем функционал, необходимый для MVP. Выбираем подходящий тариф.</p>
      </ListItem>
      <ListItem>
        <h3>Разработка и запуск</h3>
        <p>Разрабатываем и сдаем продукт в обозначенные сроки.</p>
      </ListItem>
    </List>
  </Container>
)
