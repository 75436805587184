import React from 'react'
import styled from 'styled-components'

import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Title } from '@/components/common/cases/title/Title'

import img1 from '../images/timetable/timetable-1.png'
import img2 from '../images/timetable/timetable-2.png'

const Container = styled(BlockContainer)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 16px;
  margin-bottom: 145px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 178px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-bottom: 258px;
  }
`

const StyledTitle = styled(Title)`
  position: absolute;
  top: -80px;
  left: 16px;

  p,
  h3 {
    width: 282px;
  }

  @media (min-width: 768px) {
    top: -90px;
    left: 80px;

    p,
    h3 {
      width: 282px;
    }
  }

  @media (min-width: 1100px) {
    top: 826px;
    left: 0;

    p,
    h3 {
      width: 282px;
    }
  }
`

const Img1 = styled.img`
  display: block;
  margin: 0;
  height: 252px;
  margin-bottom: 20px;
  margin-right: auto;
  transform: translateX(-5px);

  @media (min-width: 768px) {
    height: 588px;
    margin-right: 0;
    margin-left: auto;
    transform: translateX(165px);
  }

  @media (min-width: 1100px) {
    height: 987px;
    margin-bottom: 120px;
    transform: translateX(237px);
  }
`

const Img2 = styled.img`
  display: block;
  margin: 0;
  height: 357px;
  margin-left: auto;
  transform: translateX(0px);

  @media (min-width: 768px) {
    height: 830px;
    margin-left: 0;
    margin-right: auto;
    transform: translateX(-98px);
  }

  @media (min-width: 1100px) {
    height: 1146px;
    transform: translateX(-20px);
  }
`

export const TimeTable = () => (
  <Container>
    <StyledTitle
      title="Расписание"
      subtitle="Коуч может составлять удобное для себя расписание и отслеживать,  кто к нему записался в календаре."
    />
    <Img1 src={img1} />
    <Img2 src={img2} />
  </Container>
)
