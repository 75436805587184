import React from 'react'
import styled from 'styled-components'

import { HashLink } from 'react-router-hash-link'

import { BlockContainer } from '@/components/block-container/BlockContainer'

import facebookImage from './images/hero/facebook-image.svg'
import vkImage from './images/hero/vk-image.svg'
import instagramImage from './images/hero/instagram-image.svg'

const Container = styled(BlockContainer)`
  display: block;
  padding: 64px 16px 128px;
  position: relative;

  @media (min-width: 768px) {
    padding: 40px 80px 61px;
    margin-bottom: 60px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    margin-bottom: 0px;
    height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const TextContainer = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 20px;

  @media (min-width: 1100px) {
    margin: 0;
    padding: 0;
    width: 440px;
  }
`

const MainText = styled.h1`
  width: 288px;
  font-size: 16px;
  line-height: 20px;
  color: #323034;
  font-weight: bold;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    width: 400px;
    margin-bottom: 12px;
  }

  @media (min-width: 1100px) {
    width: 400px;
  }
`

const SubTitle = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  width: 288px;
  color: #323034;

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (min-width: 1100px) {
    font-size: 16px;
    line-height: 20px;
    width: 440px;
  }
`

const OrderButton = styled(HashLink)`
  display: inline-block;
  color: #323034;
  background-color: white;
  border: 1px solid #323034;
  line-height: 18px;
  transition: all 0.2s ease;
  border-radius: 4px;
  padding: 9px 12px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.4);
  }

  &:active {
    color: white;
    background-color: #323034;
  }

  @media (min-width: 768px) {
    margin-top: 24px;
    font-size: 22px;
    padding: 10px 50px;
    line-height: 28px;
  }
`

const MainImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 80%;

  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 1100px) {
    position: absolute;
    right: 0;
    top: 113px;
  }
`

const Socials = styled.div`
  padding: 0;
  right: 16px;
  bottom: 60px;
  position: absolute;
  display: flex;
  z-index: 2;

  @media (min-width: 768px) {
    right: 80px;
    bottom: 80px;
    flex-direction: column;
  }

  @media (min-width: 1100px) {
    right: 0;
    bottom: 120px;
  }

  /* temp hide socials */
  display: none;
`

const SocialLink = styled.a`
  width: 28px;
  height: 28px;
  margin-left: 12px;

  @media (min-width: 768px) {
    margin-left: 0;
    margin-top: 12px;
  }
`

export const UniqueHero = ({ mainImage, mainText, subTitle, buttonText }) => (
  <Container>
    <TextContainer>
      <MainText>{mainText}</MainText>
      {subTitle ? <SubTitle>{subTitle}</SubTitle> : ''}
      <OrderButton to="#rates">{buttonText}</OrderButton>
    </TextContainer>
    <MainImage src={mainImage} />
    <Socials>
      <SocialLink href="#">
        <img src={facebookImage} />
      </SocialLink>
      <SocialLink href="#">
        <img src={instagramImage} />
      </SocialLink>
      <SocialLink href="#">
        <img src={vkImage} />
      </SocialLink>
    </Socials>
  </Container>
)
