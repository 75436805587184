import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutWithForm } from '@/layouts/LayoutWithForm'
import { GrayWrapper } from '@/components/common/cases/gray-wrapper/GrayWrapper'
import { Hero } from './content/Hero'
import { Posts } from './content/Posts'
import { Capsule } from './content/Capsule'
import { PhotoVideo } from './content/PhotoVideo'
import { Bio } from './content/Bio'
import { Question } from './content/Question'
import { Stats } from './content/Stats'
import { Search } from './content/Search'
import { Adaptive } from './content/Adaptive'

export function LeavTrace() {
  return (
    <LayoutWithForm pageName="CASE_LEAVTRACE">
      <Helmet>
        <title>Проект цифрового бесмертия - HeksRay</title>
      </Helmet>
      <Hero />
      <GrayWrapper>
        <Posts />
        <Capsule />
        <PhotoVideo />
        <Bio />
        <Question />
        <Stats />
        <Search />
        <Adaptive />
      </GrayWrapper>
    </LayoutWithForm>
  )
}
