import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'
import { Card } from './Card'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  margin-top: 80px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    margin-top: 120px;
    padding: 0 80px;
    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    margin-top: 60px;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    max-width: 620px;
  }

  @media (min-width: 1100px) {
    width: 620px;
  }
`

export const OtherServices = ({ services, title }) => (
  <Container>
    <BlockHeader title={title} />
    <Wrapper>
      {services.map(({ title, description, image, key, link }) => (
        <Card title={title} description={description} image={image} key={key} link={link} />
      ))}
    </Wrapper>
  </Container>
)
