import * as React from 'react'
import styled from 'styled-components'

const Container = styled.button`
  color: ${props => (props.white ? '#323034' : 'white')};
  background-color: ${props => (props.white ? 'white' : '#323034')};
  border: ${props => (props.white ? '1px solid #323034' : 'none')};
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 12px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.4);
  }

  &:active {
    color: white;
    background-color: #323034;
  }

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    padding: 10px 50px;
  }
`

export const Button = ({ children, white, className }) => (
  <Container white={white} className={className}>
    {children}
  </Container>
)
