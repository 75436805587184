import App from './App'
import { Router } from 'react-router-dom'
import React from 'react'
import { hydrate } from 'react-dom'
import { history } from '@/lib/routing'
import ScrollToTop from '@/lib/routing/ScrollToTop'

hydrate(
  <Router history={history}>
    <ScrollToTop />
    <App />
  </Router>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
