import React, { useState } from 'react'
import styled from 'styled-components'

import { content } from './advantages/content'

import { BlockContainer } from '@/components/block-container/BlockContainer'

const StyledContainer = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  position: relative;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const Title = styled.h2`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #323034;

  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;

    margin-bottom: 12px;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`

const Nav = styled.nav`
  width: 100%;

  @media (min-width: 768px) {
    width: 369px;
  }
`

const NavItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  h3 {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #323034;

    margin-bottom: 12px;
  }

  p {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #323034;
  }

  br {
    display: block;
    margin: 10px 0;
    content: ' ';
    line-height: 22px;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 187px;
    margin-top: 20px;
  }

  @media (min-width: 768px) {
    position: relative;
    padding-left: 14px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    h3 {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #323034;

      margin-bottom: 4px;
    }

    p {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #323034;
    }

    img {
      display: none;
    }

    &.inactive {
      h3 {
        color: #9b9b9b;
      }

      p {
        color: #9b9b9b;
      }
    }
  }
`

const Line = styled.i`
  display: none;

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: #323034;
  }
`

const CurrentImage = styled.img`
  display: none;

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 474px;

    max-height: 197px;
  }

  @media (min-width: 1100px) {
    left: 454px;

    min-height: 256px;
    max-height: 304px;
  }
`

export const Advantages = ({ className }) => {
  const [activeFeature, setActiveFeature] = useState(0)

  return (
    <StyledContainer className={className}>
      <Title>MVP - это:</Title>
      <Nav>
        {content.map((item, index) => (
          <NavItem
            key={item.id}
            onClick={() => setActiveFeature(index)}
            active={activeFeature === index}
            className={activeFeature !== index ? 'inactive' : ''}
          >
            {activeFeature === index ? <Line /> : ''}
            <h3>
              {item.id}. {item.title}
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: item.descr,
              }}
            />
            <img src={item.image} />
          </NavItem>
        ))}
      </Nav>
      <CurrentImage src={content[activeFeature].image} />
    </StyledContainer>
  )
}
