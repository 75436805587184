import React from 'react'
import styled from 'styled-components'

import { BlockHeader } from '@/components/block-header/BlockHeader'
import { BlockContainer } from '@/components/block-container/BlockContainer'

const Container = styled(BlockContainer)`
  display: block;
  padding: 0 16px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding: 0 80px;
    margin-bottom: 120px;
  }

  @media (min-width: 1100px) {
    padding: 0;
  }
`

const List = styled.ul`
  margin: 0 auto;
  padding: 0;
  margin-top: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  width: 280px;

  @media (min-width: 768px) {
    margin-top: 60px;
    width: 470px;
  }

  @media (min-width: 1100px) {
    width: 980px;
  }
`

const Item = styled.li`
  margin: 0;
  padding: 35px 0;
  width: 130px;
  height: 150px;
  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.2);
  border-radius: 16px;

  @media (max-width: 767px) {
    margin-bottom: 20px;

    &:nth-child(2n + 1) {
      margin-right: 20px;
    }

    &:nth-child(n + 5) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 1099px) {
    margin-bottom: 40px;
    margin-right: 40px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(n + 4) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1100px) {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #323034;
  margin: 0 auto;
  margin-bottom: 16px;
`

const Rate = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323034;
`

export const Developers = () => (
  <Container>
    <BlockHeader title={'Наши специалисты'} />
    <List>
      <Item>
        <Title>Design</Title>
        <Rate>2400 р/ч</Rate>
      </Item>
      <Item>
        <Title>Backend</Title>
        <Rate>2400 р/ч</Rate>
      </Item>
      <Item>
        <Title>PM</Title>
        <Rate>1800 р/ч</Rate>
      </Item>
      <Item>
        <Title>QA</Title>
        <Rate>1200 р/ч</Rate>
      </Item>
      <Item>
        <Title>Web</Title>
        <Rate>1800 р/ч</Rate>
      </Item>
      <Item>
        <Title>Mobile</Title>
        <Rate>1800 р/ч</Rate>
      </Item>
    </List>
  </Container>
)
