import React from 'react'

import { CaseHero } from '@/components/common/case-hero/CaseHero'

import logo from '../images/hero/logo.svg'

export const Hero = () => {
  const props = {
    logo,
    title: 'Приложение для кубинга',
    subtitle: 'Приложение с уникальным таймером для спидкубинга, которым пользуется весь мир',
    tags: ['Design', 'iOS', 'Android', 'Management', 'Q&A'],
    area: `<a href="https://cubingtime.com" target="_blank">Cubingtime</a> — самое крупное сообщество по сборке кубика Рубика в России. Проект пользуется большой популярностью из-за удобного таймера для сборок
    и режима онлайн соревнований.`,
    role: `В результате тесного взаимодействия с основателями проекта и программистами сайта получилось создать приложение, которое хвалят спидкуберы по всему миру за его простоту и интуитивность!`,
    features: [
      'Таймер для сборок',
      'Удобное управление жестами',
      'Запутывание для более, чем 10 головоломок',
      'Онлайн-соревнования',
      'Темная тема',
    ],
    task: 'Создать мобильное приложение с функционалом сайта, но более быстрое и удобное.',
  }

  return <CaseHero {...props} />
}
