import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutWithForm } from '@/layouts/LayoutWithForm'
import { GrayWrapper } from '@/components/common/cases/gray-wrapper/GrayWrapper'
import { Hero } from './content/Hero'
import { List } from './content/List'

export function OPV() {
  return (
    <LayoutWithForm pageName="CASE_OPV">
      <Helmet>
        <title>Платформа 3D туров по квартирам</title>
      </Helmet>
      <Hero />
      <GrayWrapper>
        <List />
      </GrayWrapper>
    </LayoutWithForm>
  )
}
