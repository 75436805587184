import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container = styled.div`
  width: 100%;

  display: none;

  /* временно скрыт весь низ */
`

const LangSwitcher = styled.div`
  display: flex;
  justify-content: flex-end;

  display: none;
  /* временно скрыт переключатель языков */
`

const LangButton = styled.button`
  background: none;
  outline: none;
  color: white;
  font-size: 16px;
  border: none;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  cursor: pointer;
`

const LangButtonSeparator = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
`

const SeparatingLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: white;
  margin-top: 8px;
`

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  margin-left: 32px;
  font-size: 12px;
  font-weight: 500;
  position: relative;

  &:not(:first-child)::before {
    content: '';
    height: 15px;
    width: 1px;
    background: white;
    position: absolute;
    left: -16px;
    bottom: -1px;
  }
`

const Text = styled.p``

export const Bottom = ({ className }) => {
  return (
    <Container className={className}>
      <LangSwitcher>
        <LangButton active>Ru</LangButton>
        <LangButtonSeparator>/</LangButtonSeparator>
        <LangButton>Eng</LangButton>
      </LangSwitcher>
      <SeparatingLine />
      <ListContainer>
        <StyledLink to="/">
          <Text>Privacy Policy</Text>
        </StyledLink>
        <StyledLink to="/">
          <Text>Website Terms</Text>
        </StyledLink>
      </ListContainer>
    </Container>
  )
}
