import React from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'

const CardWrapper = styled(Link)`
  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(47, 48, 50, 0.2);
  border-radius: 16px;
  width: 288px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #323034;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #323034;
    letter-spacing: 0.03em;

    @media (min-width: 1100px) {
      width: 220px;
    }
  }

  @media (min-width: 768px) {
    width: 280px;
    padding: 32px 30px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const CardImg = styled.img`
  display: block;
  margin-bottom: 36px;
  width: 80px;

  @media (min-width: 768px) {
    width: 170px;
  }
`

export const Card = ({ title, description, image, link }) => (
  <CardWrapper to={link}>
    <CardImg src={image} />
    <h3>{title}</h3>
    <p>{description}</p>
  </CardWrapper>
)
